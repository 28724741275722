import { Box, Button, Flex, Image, SimpleGrid, Text } from "@chakra-ui/react";
import React, { useEffect, useState,useContext  } from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { ModalComponent } from "../../components/Modal";
import { useTranslation } from 'react-i18next';
import "./MyPurchases.css";
import ProductService from "../../apis/product.api";
import axios from 'axios';
const redHeart = require("./red-heart.png");

const MyWishlist = () => {
  const { t } = useTranslation();
  const [wishlistList, setWishlistList] = useState();
  const [isData, setIsData] = useState(false);

  const [item, setitem] = useState();

  const [purchases, setPurchases] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBox, setSelectedBox] = useState({});
  
  const productApiService = new ProductService();
  const handleClick = (product) => {
    setIsModalVisible(true);
    setSelectedBox(product);
  };



  const removeItemHandler = (item) => {
   
  }

  
  const getData = async () => {
   const res = await productApiService.getPurchasedProduct();
   setPurchases(res);
  }
 

 useEffect(() => {
  
  getData();
  },[])

  const handleCartButtonClick = async (_id) => {
    // setProducts(prevState => prevState.filter(item => item.id !== _id))
    const data = {product_id: _id}
    const res = await productApiService.likeUnlikeProduct(data);
  }

  return (
    <>
      <div className="my-purchases" style={{minHeight:"54.5vh"}}>
      <h1>{t("my_purchases")}</h1>
      <hr style={{ margin: "10px 0" }} />
      <Box>
        {purchases.map((purchase,index) => {
          return (
            purchase.product ? 
            (<>
            <Box direction="row" key={index} className="my-purchases-list-item">
              <Box width="5%">
                <img src={purchase.product.image}  className="wishlist-page-image"/>
              </Box>
              <Box width="60%" className="wishklist-product-name">
                <a href={`/product/${purchase.product.slug}`}>
                  <Text fontWeight="bold">{purchase.product.brand}</Text>
                  <Text >{purchase.product.name}</Text>
                </a>
               
              </Box>
              <Box width="20%">
              <Text fontWeight="bold">{purchase.purchase_date}</Text>
              </Box>
              <Box width="10%">
              <Text fontWeight="bold">${purchase.product.price}</Text>
              </Box>
              <Box width="10%">
              <Button
                // onClick={addToCartHandler}
                size={"lg"}
                pl={10}
                pr={10}
                borderRadius={50}
                fontSize="14px"
                colorScheme="red"
              >
                {t("add_to_basket")}
              </Button>
             
              </Box>
              
            </Box>
            <hr style={{ margin: "10px 0" }} />
            </>) : null
            
          )
          
        })}
       
      </Box>
      </div>
    </>
  );
};

export default MyWishlist;
