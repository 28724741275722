import { VStack, Image, Box, Text, Heading, Button } from "@chakra-ui/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./searchProduct.css";

export default function SearchProductListCard({ setSearchResultPopup, setSearchResults, searchResults }) {
  const navigate= useNavigate();

  const handleSearchProductClick = (url) => {
    setSearchResultPopup(false);
    setSearchResults([]);
    navigate(url);
  }
  return (
    <>
      {searchResults.length > 0? 
        <ul className="search-products-card">
          {searchResults.map((product,idx) => {
            return (
              <li className="search-product-item">
                {/* <Link to={`/product/${product.slug}`} > */}
                  <Box onClick={(e)=>handleSearchProductClick(`/product/${product.slug}`)} cursor={"pointer"}>
                    {/* <Image
                    display={"inline-block"}
                        src={product.image}
                        alt={product.name}
                        w="60px"
                        // className="product-card-image"
                        borderRadius="md"
                      /> */}
                    <Box display={"inline-block"}>
                    <Text className="product-card-brand" pl={1}>
                      {product.brand}
                    </Text>
                    <Text className="product-card-name" fontFamily={"inherit"} pl={1}>{product.name}</Text>
                    {/* <Text color={"black"} backgroundColor={"orange.200"} w={"100%"} p="2"> colors</Text> */}
                    {/* <Text fontWeight={"bold"} fontSize={"20px"} color={"tomato"} w={"100%"}>{rating}☆</Text> */}
                    <Text className="product-card-price" fontWeight={"bold"} fontSize={"13px"} pl={1}>${product.price}</Text>
                    </Box>
                  </Box>
                {/* </Link> */}
              </li>
            )
          })}
        </ul> : null}
      {/* </Box> */}
    </>
  );
}