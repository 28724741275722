import { Box, Stack, Text,Flex, useMediaQuery, Button, Grid, GridItem, WrapItem  } from "@chakra-ui/react";
import "./Cart.css";
import CartItem from "./CartItem";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate  } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import ProductService from "../../apis/product.api";

const Cart = () => {
  const { t } = useTranslation();
  const cart = useSelector((state) => state.cart).cart;
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");
  const productApiService = new ProductService();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const checkout = () => {
    navigate(`/checkout`);
  };


  const handleDecreaseQty = async (sku) => {
    const payload = {
      "quantity": 1,
      "sku": sku
    }
    const result = await productApiService.removeFromCart(payload);
    dispatch({type: 'UPDATE_CART',payload: result});
  }

  const handleDeleteFromCart = async (sku) => {
    const payload = {
      "sku": sku
    }
    const result = await productApiService.removeFromCart(payload);
    dispatch({type: 'UPDATE_CART',payload: result});
  }

  const handleIncreaseQty = async (product_id, variant_id, sku) => {
    const variant_object = await productApiService.getVariant(sku);
    if(variant_object.quantity > 0){
      const payload = {
        "product_id": product_id,
        "variant_id": variant_id,
        "sku": sku,
        "quantity": 1
      }
      
      const result = await productApiService.addToCart(payload);
      dispatch({type: 'UPDATE_CART',payload: result});
    }else{
      alert(t("out_off_stock"));
    }
  }

  return (
    <div className="cart_main" style={{minHeight:"64vh"}}>
      <Stack>
      <Box className="cart-main-grid" templateColumns='repeat(4, 1fr)' gap={4}>
        <GridItem className="item-box" colSpan={3}>
          <Text className="head-basket">{t("my_basket")}</Text>
          <Box borderWidth="1px" p="4" borderRadius="md" boxShadow="md" marginTop="10px">
            <Box className="cart-items-color">
              <CartItem
              t={t}
              cart={cart}
              handleDecreaseQty={handleDecreaseQty}
              handleIncreaseQty={handleIncreaseQty}
              handleDeleteFromCart={handleDeleteFromCart} />
            </Box>
          </Box>
          <Box mt="20px" mb="20px">
            <WrapItem style={{float: "right"}}>
              <Button colorScheme='green' isDisabled={!(cart && cart.items.length > 0) ? true : false} onClick={(e)=>checkout()} className="checkout-button" >{t("checkout")}</Button>
            </WrapItem>
          </Box>
        </GridItem>
      </Box>
    </Stack> 
 
  </div>
   
  );
};

export default Cart;
