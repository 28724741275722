// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ProductService from "../apis/product.api";

const productApiService = new ProductService();

i18n
  .use(initReactI18next)
  .init({
    resources: {},
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  }).then(async () => {
    // Add more languages as needed
    const lang = localStorage.getItem("lang") !== "" ? localStorage.getItem("lang") : "en";

    const translations = await productApiService.getallLanguageTranslation();
    const languages = translations.lang_codes;
    for (const language of languages) {
      i18n.addResourceBundle(language, 'translation', translations.languages[language]);
    }
    i18n.changeLanguage(lang);
  });
export default i18n;
