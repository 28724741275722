import React, { useEffect } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Image,
  SimpleGrid,
  Text,
  Flex
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ProdcuctService from "../../apis/product.api"
import AccordionComponent from "./AccordionComponent";
import "./Pernalizations.css";

const MyOrder = () => {
  const lang = useSelector((state) => state.siteLanguage).lang;

  const { t } = useTranslation();
  const ProdcuctApiService = new ProdcuctService();
  const [attributeGroups, setAttributeGroups] = useState([]);
  const [changesMade, setChangesMade] = useState(false);

  
  const fetchData = async () =>{
    const results = await ProdcuctApiService.getAllPernalizationsAttributes();
    setAttributeGroups(results);
  }

  const handleSaveData = async () =>{
    const results = await ProdcuctApiService.createPersonalizationProfile(attributeGroups);
    setAttributeGroups(results);
    setChangesMade(false);
    toast.success(t("profile_updated"), {
      position: "top-right", // You can adjust the position
      autoClose: 3000, // Time in milliseconds for the toast to auto close
    });
  }

  useEffect(() => {
    fetchData();
  }, [lang]);

  return (
    <>
    <div className="personalization-main" style={{minHeight: "54.5vh"}}>
      <h1>{t("personalized_profile")}</h1>
      {attributeGroups.map((group,index) => {
        return (
          <Box className="attributes-groups">
            <h1 style={{textTransform: "capitalize", marginTop: "0px"}}>{group.name}</h1>
            <Box paddingLeft={"30px"}>
              {group.attributes.map((attribute,idx) =>{
                return (
                  <AccordionComponent
                    key={idx}
                    heading={attribute.name}
                    subHeading={attribute.question}
                    attribute_options={attribute.attribute_options}
                    setAttributeGroups={setAttributeGroups}
                    setChangesMade={setChangesMade} />
                )
              })}
            </Box>
          </Box>
        )
      })}
      
        <Box textAlign={"center"} marginTop={"40px"} marginBottom={"40px"}>
          <Button onClick={(e) => handleSaveData()} variant="outline" colorScheme='teal' isDisabled={!changesMade}>Update Profile</Button>

        </Box>

    </div>
  </>
  );
};

export default MyOrder;