import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../components/Auth/login";
// import Phonelogin from "../components/Auth/Phonelogin";
import Signup from "../components/Auth/signup";

import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import Home from "../pages/home/home";
import MyOrder from "../pages/my-orders/MyOrder";
import MyWishlist from "../pages/my-wishlist/MyWishlist";
import MyPurchase from "../pages/my-purchases/MyPurchases";
import ProductList from "../pages/product/product";
import Brand from "../pages/product/Brand";
import MyAccounts from "../components/my-accounts/MyAccounts";
import Dashboard from "../components/Dashboard";

import SingleProduct from "../pages/SingleProduct/SingleProduct";
import Pernalization from "../pages/Pernalizations/Pernalizations";
import Cart from "../pages/cart/Cart";
import Checkout from "../pages/checkoutPage/Checkout";
import OrderDetailsPage from "../pages/orderDetails/OrderDetailsPage";
import Place from "../pages/checkoutPage/Place";
import PrivateRoute from "../components/PrivateRoute";
import ProfessionalLogin from "../components/Auth/ProfessionalLogin";
import ProfessionalSignup from "../components/Auth/ProfessionalSignup";
import ContactUs from "../pages/contact-us/ContactUs";
import Subscription from "../pages/subscription/Subscription";

export default function AllRoutes() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        <Route
          path="/login"
          element={
            <>
              <Navbar />
              <Login />
              <Footer />
            </>
          }
        />
        <Route
          path="/signup"
          element={
            <>
              <Navbar />
              <Signup />
              <Footer />
            </>
          }
        />
         <Route
          path="/professional-login"
          element={
            <>
              <Navbar />
              <ProfessionalLogin />
              <Footer />
            </>
          }
        />
        <Route
          path="/professional-signup"
          element={
            <>
              <Navbar />
              <ProfessionalSignup />
              <Footer />
            </>
          }
        />
        <Route
          path="/myorders"
          element={
            <PrivateRoute>
              <Navbar />
              <MyOrder />
              <Footer />
            </PrivateRoute>
          }
        />
        <Route
          path="/mywishlists"
          element={
              <PrivateRoute>
                <Navbar />
                <MyWishlist />
                <Footer />
              </PrivateRoute>
          }
        />
        <Route
          path="/mypurchases"
          element={
            <>
              <PrivateRoute>
                <Navbar />
                <MyPurchase />
                <Footer />
              </PrivateRoute>
            </>
          }
        />
        <Route
          path="/myaccount"
          element={
            <PrivateRoute>
              <Navbar />
              <MyAccounts />
              <Footer />
            </PrivateRoute>
          }
        />
         <Route
          path="/contact-us"
          element={
            <>
              <Navbar />
              <ContactUs />
              <Footer />
            </>
          }
        />
        <Route
          path="/products/:type/:slug/"
          element={
            <>
              <Navbar />
              <ProductList />
              <Footer />
            </>
          }
        />
        <Route
          path="/products"
          element={
            <>
              <Navbar />
              <ProductList />
              <Footer />
            </>
          }
        />
        <Route
          path="/products/:type/:slug/:subCatSlug"
          element={
            <>
              <Navbar />
              <ProductList />
              <Footer />
            </>
          }
        />
        <Route
          path="/brands-list"
          element={
            <>
              <Navbar />
              <Brand />
              <Footer />
            </>
          }
        />
        <Route
          path="/basket/:id"
          element={
            <>
              <Navbar />
              <Cart />
              <Footer />
            </>
          }
        />
        <Route
          path="/basket"
          element={
            <PrivateRoute>
              <Navbar />
              <Cart />
              <Footer />
            </PrivateRoute>
          }
        />
        <Route
          path="/checkout"
          element={
            <PrivateRoute>
              <Navbar />
              <Checkout />
              <Footer />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment"
          element={
            <PrivateRoute>
              <Navbar />
              <Place />
              <Footer />
            </PrivateRoute>
          }
        />
        <Route
          path="/product/:slug"
          element={
            <>
              <Navbar />
              <SingleProduct />
              <Footer />
            </>
          }
        />
      <Route
          path="/pernalizations"
          element={
            <PrivateRoute>
              <Navbar />
              <Pernalization />
              <Footer />
            </PrivateRoute>
          }
        />
        <Route
          path="/orders/:id"
          element={
            <PrivateRoute>
              <Navbar />
              <OrderDetailsPage />
              <Footer />
            </PrivateRoute>
          }
        />
        <Route
          path="/subscription"
          element={
            <PrivateRoute>
              <Navbar />
              <Subscription />
              <Footer />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
}
