import axios from 'axios';
import React, { useContext, useEffect, useState} from 'react'
import { Heading, Link, Text } from "@chakra-ui/react";
import "./OfferBanner.css";
const OfferBanner = () => {
  const [items, setActiveItemIndex] = useState([]);
  const chevronWidth = 8;
  useEffect(() => {

    const fetchData = async () => {
        
      try {
        const result = await axios.get(`api/products/banner/all`);
        console.log(result.data);
        setActiveItemIndex(result.data)



      } catch(err) {
        console.log("Error!");
      }
      
    }
    fetchData();

  }, []);
  return (
   
    <div className="banner_container"> 
      {items.map((item) => (
      <div className="banner_item">
        <div
          style={{ backgroundColor: "#fecfc5" }}
          className="banner_item_txt_box"
        >
          <Heading as="h4" size="md" mt="-5px">
          {item.name}
          </Heading>
          <Text fontSize="sm" ml="0px" mt="0px">
            <Link to="#">{item.text}</Link>
          </Text>
          <button>
            <Link to="#">SHOP NOW▸</Link>
          </button>
        </div>
        <div className="banner_item_img_box">
        <img alt="test product" src={item.image} />
        </div>
      </div>
       ))}
    </div>
  );
};

export default OfferBanner;
