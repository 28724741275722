import { useElements, useStripe } from '@stripe/react-stripe-js';
import React, {useState, useEffect} from "react";
import { 
    Box,  
    Input,
    Button,
    FormControl,
    FormLabel,
    VStack,
    Text,
    Flex,
    RadioGroup,
    Radio } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import OrderService from "../../apis/order.api";

const ConfirmPayment = ({setPaymentMethodState,shippingAddressState, clientSecret,setActiveStep}) => {
  const { t } = useTranslation();
  const orderApiService = new OrderService();
  const [error, setError] = useState(null);

  const stripe = useStripe();
//   const elements = useElements();
if (clientSecret !== ""){
    console.log(clientSecret);
    const elements = stripe.elements();
    const paymentElement = elements.create('payment', {
      clientSecret: clientSecret, // Replace with your actual client secret
    });
    
    paymentElement.mount('#payment-element'); 
}

//   useEffect(() => {
//     if (stripe && clientSecret !== "") {
        
//         // Create a payment element with the clientSecret
//         const paymentElement = elements.create('payment', {
//             clientSecret: clientSecret,
//         });
      
//         // Use the payment element in your form
//         paymentElement.mount('#payment-element');
//     }
//   }, [stripe]);

const handleSubmit = async (event) => {
    event.preventDefault();
    // const card = elements.getElement(CardElement);
   
  
  }


  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: 'inherit',
        '::placeholder': {
          color: '#718096',
        },
        '::selection':{
            border: '1px solid red'
        },
        border: '1px solid #2c7a7b',
        lineHeight: "35px",
        borderSize: "1px"
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };


return (
    <Box marginTop={"20px"} width={"100%"} className="payment-method-form-main">
        <form onSubmit={handleSubmit} className="stripe-form">
            <VStack spacing={4}>
                <Box className="checkout-card-form-main">
                {/* <FormControl>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input placeholder="Email" required value={email} onChange={(event) => { setEmail(event.target.value)}} />
                </FormControl> */}
                <Box className="form-row" width={"100%"} >
                    <FormLabel for="card-element">Credit or debit card</FormLabel> 
                    <div id='payment-element'></div>
                    {/* <PaymentElement /> */}
                    {/* <CardElement id="card-element" className="stripe-card-input-box" options={cardElementOptions} /> */}
                    <div className="card-errors" role="alert">{error}</div>
                </Box>
                <Button variant="outline" color={"#2c7a7b"} borderColor={"#2c7a7b"} type="submit">{t("make_payment")}</Button>
                </Box>
            </VStack>
        </form>
    </Box>
 );
};
export default ConfirmPayment;