import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from "@chakra-ui/react";
import React from "react";

function AccordionComponent({ heading, subHeading, attribute_options, setAttributeGroups, setChangesMade }) {

  const handleAttributeSelect = (attribute_option) => {

    setAttributeGroups((prevAttributes) => {
      return prevAttributes.map((group) => {
        if (group.group === attribute_option.group) {
          return {
            ...group,
            attributes: group.attributes.map((attribute) => {
              if (attribute.id === attribute_option.attribute) {
                const updatedOptions = attribute.attribute_options.map((option) => {
                  if (option.id === attribute_option.id) {
                    return {
                      ...option,
                      selected: !option.selected,
                    };
                  }
                  if (attribute.type === "SINGLE") {
                    return {
                      ...option,
                      selected: false,
                    };
                  }
                  return option;
                });
    
                return {
                  ...attribute,
                  attribute_options: updatedOptions,
                };
              }
              return attribute;
            }),
          };
        }
        return group;
      });
    });
  //   setAttributeGroups((prevAttributes) =>
  //   prevAttributes.map((attribute) => {
  //     if (attribute.id === attribute_option.attribute) {
  //       const updatedOptions = attribute.attribute_options.map((option) => {
  //       if (option.id === attribute_option.id) {
  //         return {
  //           ...option,
  //           selected: !option.selected,
  //         };
  //       }
  //       if(attribute.type === "SINGLE"){
  //         return {
  //           ...option,
  //           selected: false,
  //         };
  //       }
  //       return option;
  //     });

  //     return {
  //       ...attribute,
  //       attribute_options: updatedOptions,
  //     };
  //   }
  //   return attribute;
  // }));
  setChangesMade(true);
  }
  return (
    <Box>
      <Box borderBottom={"1px solid #cecece"} marginBottom={"10px"} paddingBottom={"15px"}>
              <>
                <h2 style={{background:'#ededed',padding: "10px"}}>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={"20px"} as="b">
                        {heading}
                      </Text>
                    </Box>
                </h2>
                  <Text mt={5} mb={5}>
                    {subHeading}
                  </Text>
                  <Box>
                    {attribute_options.map((attribute_option,idx)=> {
                      return (
                        <Box onClick={(e)=>handleAttributeSelect(attribute_option)} className={`attributes-item ${attribute_option.selected ? "selected" : ""}`} key={idx}>
                          <img src={attribute_option.icon_image} width={"30px"}style={{display: "inline-block"}}/>
                          <Text display={"inline-block"}>{attribute_option.option}</Text>
                          </Box>
                      )
                    })}
                  </Box>
                  <Box style={{clear: "both"}}></Box>
              </>
      </Box>
    </Box>
  );
}

export default AccordionComponent;
