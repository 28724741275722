import React, { useEffect, useState,useReducer } from "react";
import { Box, Text, Stack, UnorderedList, ListItem } from "@chakra-ui/react";
import { Grid, GridItem } from '@chakra-ui/react'
import ProductService from "../../apis/product.api";

import "./product.css";
const Brand = () => {
    const [reload, setReload] = useState(true);
    const [brands, setBrands] = useState({});
    const productApiService = new ProductService();
    

    const getData  = async ()  => {
        const res = await productApiService.getAtoZBrands();
        console.log(res);
        setBrands(res);
        setReload(false);
    }

    
  useEffect(() => {
    if(reload){
      getData();
    }
  });

    return (
        <div className="brands_main">
            <div className="brand-alphabet-list">
                {Object.keys(brands).map((apl, index) =>{
                    return (
                        <a href={`#${apl}`} key={index}>
                            {apl}
                        </a>
                    )
                })}
            </div>
            <hr style={{ margin: "50px 0" }} />
            <div className="brand-list-alphabet">
                {Object.keys(brands).map((apl, index) =>{
                    let list = [...brands[apl]]; 
                    const PartIndex = Math.ceil(list.length / 4);
                    const firstPart = list.splice(-PartIndex);
                    const thirdPart = list.splice(-PartIndex);
                    const secondPart = list.splice(-PartIndex);
                    const FourthPart = list;

                    return (
                        <>
                        <div className="brand-name-alp">
                            <a href={`#${apl}`} key={index} className="brand-list-anchor first">
                                {apl}
                            </a>
                        </div>
                        <Grid templateColumns='repeat(5, 1fr)' gap={6} id={apl}>
                            <GridItem w='100%' className="brand-name-alp-lg">
                                <div>
                                    <a href={`#${apl}`} key={index} className="brand-list-anchor first">
                                        {apl}
                                    </a>
                                </div>
                            </GridItem>
                            <GridItem w='100%'>
                                {firstPart.map((brand,idx) => {
                                    return (
                                        <div>
                                            <a key={idx} href={`/products/brand/${brand.slug}`} className="brand-list-anchor">{brand.name}</a>
                                        </div>
                                        )
                                    
                                })}
                            </GridItem>
                            <GridItem w='100%'>

                                {secondPart.map((brand,idx) => {
                                     return (
                                        <div>
                                        <a key={idx} href={`/products/brand/${brand.slug}`} className="brand-list-anchor">{brand.name}</a>
                                    </div>                                    )
                                    
                                })}
                            </GridItem>
                            <GridItem w='100%'>

                                {thirdPart.map((brand,idx) => {
                                    return (
                                        <div>
                                        <a key={idx} href={`/products/brand/${brand.slug}`} className="brand-list-anchor">{brand.name}</a>
                                    </div>                                    )
                                    
                                })}
                            </GridItem>
                            <GridItem w='100%'>

                                {FourthPart.map((brand,idx) => {
                                    return (
                                        <div>
                                        <a key={idx} href={`/products/brand/${brand.slug}`} className="brand-list-anchor">{brand.name}</a>
                                    </div>                                    )
                                        
                                })}
                            </GridItem>
                        </Grid>
                        <hr style={{ margin: "50px 0" }} />
                        </>
                       
                    )
                })}
            </div>
        </div>
    )
}

export default Brand;