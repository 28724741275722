import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ProductService from "../../apis/product.api";

const BrandMenu = ({top_bar_color, lang}) => {
    const { t } = useTranslation();
    const [menus, setMenus] = useState([]);
    const [reload, setReload] = useState(true);

    const productApiService = new ProductService();

    const fetchData = async () => {
        const res = await productApiService.getProductTypes();
        setMenus(res);
        setReload(false);
    }

    useEffect(() => {
        fetchData();
      }, [lang])
      
    return (
        <>
            {
                menus.map((type, index) => {
                    return (
                        <li key={index} >
        
                            <a href={`/products/product-type/${type.name}`} 
                                onMouseEnter={(e)=>{e.target.style.color = top_bar_color}}
                                onMouseLeave={(e)=>{e.target.style.color = ""}}
                                className="nav5-li-link">
                                {type.name}
                                <span className="arrow-up"></span>
                            </a>
                            <div>
                            {(type.name).toLowerCase() === "professional" ?
                            <div>
                              <ul>
                                <li>
                                    <a href={`/products/product-type/${type.name}`} className="hover-item-li">{t("professional")}</a>
                                </li>
                                <li>
                                   <a href="/professional-login">{t("account_login")}</a>
                                </li>
                                {/* <li>
                                  <a href="/">Find a Glo Retailer</a>
                                </li> */}
                                <li>
                                     <a href="/professional-signup">{t("become_a")} Manchi {t("professional")}</a>
                                </li>
                              </ul>
                        </div>
                        :
                            <div>
                                {type.categories.map((category, idx) => {
                                    return (
                                        <ul key={idx}>
                                            <li>
                                                {/* <Link to="/products/cheek"> */}
                                                <a href={`/products/category/${category.slug}`} className="hover-item-li">
                                                    {category.name}
                                                </a>
                                                {/* </Link> */}
                                            </li>
                                            {category.sub_categories.map((sub_category, idxx) => {
                                                return (
                                                    <li key={idxx}>
                                                        {/* <Link to="/products/cheek"> */}
                                                        <a href={`/products/category/${category.slug}/${sub_category.slug}`}>{sub_category.name}</a>
                                                        {/* </Link> */}
                                                    </li>
                                                )
                                            })}
                                        
                                        </ul>
                                    )
                                })}
                        </div> }
                            </div>
                        </li>
                    )
                })
            }
        </>
    )
};

export default BrandMenu;