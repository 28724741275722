import "./App.css";
import AllRoutes from "./routes/AllRoutes";
import { ToastContainer } from 'react-toastify';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from './components/i18n';

import 'react-toastify/dist/ReactToastify.css'; 

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <div>
        <AllRoutes />
        <ToastContainer />
      </div>
    </I18nextProvider>
  );
}

export default App;
