import CookieUtil from "./cookieUtil";


const getUserId = () => {
  const userId = localStorage.getItem("userId");
  if (userId) {
    return userId;
  }
  return "";
};

const getAuthToken = () => {
  const token = localStorage.getItem("token");
  if(token){
    return {
      Authorization: "Token " + token,
    };
  }
   return {}
};


const CommonUtil = {
  getUserId: getUserId,
  getAuthToken: getAuthToken
};

export default CommonUtil;
