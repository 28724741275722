import axios from "../token.interceptor";
import { API_URL } from "../config";
import CommonUtil from "../util/commonUtil";
export default class UserService {
  constructor() {
    this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";
    this.url = `${API_URL}/${this.lang}/api/v1/users`;
    this.token = CommonUtil.getAuthToken();
  }

  async getAll() {
    const { data } = await axios.get(`${this.url}`);
    return data;
  }

  async login(FormData) {
    const { data } = await axios.post(`${this.url}/login/`, FormData);
    return data;
  }
  async professionalLogin(FormData) {
    const { data } = await axios.post(`${this.url}/professional/login/`, FormData);
    return data;
  }

  async loginWithGoogle(payload, backend) {
    const { data } = await axios.post(`${this.url}/token/${backend}/`, payload);
    return data;
  }


  async signup(FormData) {
    const { data } = await axios.post(`${this.url}/signup/`, FormData);
    return data;
  }

  async professionalSignup(FormData) {
    const { data } = await axios.post(`${this.url}/professional/signup/`, FormData);
    return data;
  }

  async createShippingAddress(FormData) {
    const { data } = await axios.post(`${this.url}/shipping/address/`, FormData,{headers: this.token});
    return data;
  }

  async getShippingAddress(FormData) {
    const { data } = await axios.get(`${this.url}/shipping/address/`,{headers: this.token});
    return data;
  }

  async getCurrentUser() {
    const { data } = await axios.get(`${this.url}/me/`,{headers: this.token});
    return data;
  }

  async updateUserinfo(userId, FormData) {
    const { data } = await axios.put(`${this.url}/me/update/${userId}/`, FormData,{headers: this.token});
    return data;
  }

  async changeAccountPassword(FormData) {
    const { data } = await axios.post(`${this.url}/change/password/`, FormData,{headers: this.token});
    return data;
  }

  
  async contactUsEmail(FormData) {
    const { data } = await axios.post(`${this.url}/contact/us/`, FormData);
    return data;
  }
}