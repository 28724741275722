import React, { useEffect, useState,useReducer } from "react";
import Sidebar from "../../components/Sidebar";
import ProductCard from "../../components/ProductCard";
import { Box, Flex, SimpleGrid, Select, Heading, Text } from "@chakra-ui/react";
import { DragHandleIcon } from '@chakra-ui/icons';
import { MakeupObject } from "../../assets/sidebar.value";
import Pagination from "../../components/Pagination";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import ProductService from "../../apis/product.api";
import "./product.css";

const initialState = {
  filter_type:"",
  filter_value:"",
  filter_value2:"",
  brands:"",
  concerns:"",
  skin_types:"",
  formulations:"",
  hooks:"",
  new:"",
  age_range:"",
  price_range:"",
  is_bestseller:false,
  is_foryou:false,
  is_offer:false,
  is_justdroped:false,
  sort_by:""
}
const Product = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [reload, setReload] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState(initialState);
  const [filters, setFilters] = useState([]);
  // const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [currentFilterSlug, SetCurrentFilterSlug] = useState("");
  const [reloadType, setReloadType] = useState("replace");
  const [showFilterSidebar, setShowFilterSidebar] = useState(false);

  let params = useParams();
  const productLikeIds = useSelector((state) => state.productLike).likedProductIds;
  const dispatch = useDispatch();

  const placeholder = require("./placeholder.jpg");
    
  const productApiService = new ProductService();

  const getData = async (offset) => {
    setReload(false);
    const type = params.type;
    const slug = params.slug;
    SetCurrentFilterSlug(type);
    let subCat = "";
    if(params.hasOwnProperty('subCatSlug')){
      subCat = params.subCatSlug;
      // setCurrentHeading(subCat);
    }

    const filterData = selectedFilters;

    filterData.filter_type = type
    filterData.filter_value = slug
    filterData.filter_value2 = subCat


    const res = await productApiService.getShopProducts(filterData,offset);
    setTotalCount(res.total_count);
    setPageSize(prevState => prevState +res.page_size);
    // setOffset(res.offset+64);
    setFilters(res.filters);
    // if(reloadType === "append"){
    //   setProducts(prevState => [...prevState, ...res.data]);
    // }else{
    //   setProducts(res.data);
    // }
    return res
  }

  const handleLoadMore = async () => {
    const data = await getData(products.length);
    setProducts(prevState => [...prevState, ...data.data]);
  }

  const handleLikeProduct =  async (_id) => {
      dispatch({type: "LIKE_PRODUCT", payload: _id});
      const data = {product_id: _id}
      const res = productApiService.likeUnlikeProduct(data);
  }

  const handleSortingChange = (e, type) => {
      setSelectedFilters(prevState => ({
        ...prevState,
        [type]: e.target.value
      }))
    setReload(true);
  }

  const handleLoadState = async () => {
      const res = await getData(0);
      setProducts(res.data)
  }

  useEffect(() => {
    if(reload && selectedFilters){
      handleLoadState();
    }
  }, [selectedFilters]);

  return (
    <div
    className="products_main"
  >
      
      <Flex>
        <Box className={`products-page-filters-main ${showFilterSidebar? 'show': ''}`}>
          <Sidebar 
            setSelectedFilters={setSelectedFilters} 
            setProductReload={setReload}
            mainFilters={filters}
            params={params}
            currentFilterSlug={currentFilterSlug}
            setShowFilterSidebar={setShowFilterSidebar}
           />
        </Box>
        <Box w={{ base: "100%", lg: "77%", xl: "77%" }} border="1px black">
        <Box style={{marginTop: "13px"}} className="sorting-main">
          <div className="products-filter-icon">
            <DragHandleIcon boxSize={6} color="gray.500" onClick={()=>setShowFilterSidebar((prev)=> !prev)}/>
          </div>
          <Text>{totalCount} {t("results")}</Text>
        <Select
         
          placeholder={t("sorting")}
          fontWeight={"bold"}
          onChange={(e)=> handleSortingChange(e,"sort_by")}
          className="sorting-box"
          width="25%"
          size='sm'
        >
          <option value="phl">{t("price_high_to_low")}</option>
          <option value="plh">{t("price_low_to_high")}</option>
          <option value="a-z">{t("ascending_order")}</option>
          <option value="z-a">{t("descending_order")}</option>
          <option value="rating">{t("sort_by_rating")}</option>
        </Select>
     </Box>
          <Box w={{ sm: "100%", md: "90%", lg: "100%" }} m="auto">
          <SimpleGrid columns={[2, 2, 3, 3, 4]} spacing="1" mt="5" mb="5">
            {products &&
              products.map((el, index) => (
                  <>
                    <ProductCard
                      key={el.id}
                      _id={el.id}
                      slug={el.slug}
                      image={el.image !== "" ? el.image : placeholder}
                      name={el.name}
                      brand={el.brand}
                      colors={el.product_colors}
                      price={el.price}
                      rating={el.rating}
                      is_liked={productLikeIds.includes(el.id) ? true : false}
                      is_pro={el.is_pro}
                      handleLikeProduct={handleLikeProduct}
                    />
                  </>
                ))}
            </SimpleGrid>
          </Box>
          <Box w={{ sm: "100%", md: "90%", lg: "100%" }} m="auto" className="load-more-main">
            <p>{products.length} {t("of")} {totalCount} {t("results")}</p>
            {totalCount > products.length+1 ?
            <button
                  style={{ backgroundColor: `#fff` }}
                  className="load-more-button"
                  onClick={()=>handleLoadMore()}
                >
                  {" "}
                  <Heading as="h6" size="xs">
                    Load more products
                  </Heading>
                </button>
                : null}
          </Box>
        </Box>
      </Flex>
   </div>
  );
};

export default Product;