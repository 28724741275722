import { Box, Button, Flex, Heading, Link, Text, Spacer } from "@chakra-ui/react";
import React, { useContext, useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ItemsCarousel from "react-items-carousel";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";
import { useTranslation } from 'react-i18next';

import ProductService from "../../../apis/product.api";
import "./ChoosenForYou.css";
const placeholder = require("./placeholder.jpg");
const ChoosenForYou = ({addToCartHandler}) => {
  const { t } = useTranslation();
  const topbar = useSelector((state) => state.topBar).topbar;
  
  const [products, setProducts] = useState([]);
  const [reload, setReload] = useState(true);
  const [itemNumber, setItemNumber] = useState(6);

  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const chevronWidth = 8;

  const productApiService = new ProductService();

  const navigate = useNavigate();

  // const getData = async () => {
  //   const res = await productApiService.getChosenProducts();
  //   setProducts(res)
  //   setReload(false);
  // }

  const getData = async () => {
    const res = await productApiService.getRecommendedProducts();
    setProducts(res)
    setReload(false);
  } 
  
  useEffect(() => {
    if(reload){
      getData();
    }
  }, []);

  useEffect(() => {
    const windowWidth = window.innerWidth;
    var numberOfCards = 6;
    if (windowWidth < 640) {
      numberOfCards = 3;
    }
    setItemNumber(numberOfCards);
  }, [window.innerWidth]);

  return (
    <div
      // style={{ padding: `${chevronWidth}px 2px` }}
      className="choose_container for-you"
    >
      <Heading as="h4" size="md" mb="30px">
        {t("choosen_for_you")}
      </Heading>
        
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={itemNumber}
        gutter={4}
        leftChevron={
          <div className="choose_carousel_btn_box_L">
            {" "}
            <ChevronLeftIcon boxSize={10} color="white" />{" "}
          </div>
        }
        rightChevron={
          <div className="choose_carousel_btn_box_R">
            {" "}
            <ChevronRightIcon boxSize={10} color="white" />{" "}
          </div>
        }
        outsideChevron
        chevronWidth={chevronWidth}
      >
        {products?.map((item, index) => {
          return (
            <div className="choose_item" key={index}>
              <Box paddingTop={"10px"} position={"relative"}>
                {item.left_badge ? 
                  <Box className="product-badge"
                    float={"left"}
                    width={"44%"}
                    textAlign={"left"}
                    backgroundColor={item.left_badge.color}>
                      <Text color={item.left_badge.text_color}>{item.left_badge.text}</Text>
                      <div className="badge-angle badge-left" 
                      style={{borderTopColor: `${item.left_badge.color}`,
                      borderBottomColor: `${item.left_badge.color}`,
                        }}
                      ></div>
                  </Box> 
                : null}
                {item.right_badge ?
                  <Box className="product-badge"
                    float={"right"}
                    width={"44%"}
                    textAlign={"right"}
                    backgroundColor={item.right_badge.color}>
                      <div className="badge-angle badge-right" 
                      style={{borderRightColor: `${item.right_badge.color}`}}
                      ></div>
                      <Text color={item.right_badge.text_color}>{item.right_badge.text}</Text>
                  </Box> 
                : null}
              </Box>
              <div className="choose_item_img_box">
                <img alt="test product" src={item.image !== "" ? item.image  : placeholder} />
                <button className="choose_item_img_btn">
                  <Heading as="h6" size="xs" p="5px">
                  <Link onClick={() => navigate(`/product/${item.slug}`)}>
                        {t("quicklook")}
                    </Link>
                  </Heading>
                </button>
              </div>
              <div
                style={{ backgroundColor: `#fff` }}
                className="choose_item_txt_box"
              >
                <Heading as="h6" size="xs" mt="-5px">
                  {item.name}
                </Heading>
                <Box position={"absolute"} width={"85%"} bottom={"50px"}>
                  {/* <Text display={"inline-block"} fontSize="sm" ml="0px" mt="5px">
                    {item.category.name}
                  </Text> */}

                  <Text 
                  display={"inline-block"} 
                  fontWeight={"bold"} 
                  float={"right"} 
                  mt="5px"
                  marginLeft={'10px'}
                  className="chosen-item-price">
                    ${item.variant.price} 
                    {item.variant.discount_added ? 
                    (<Text display={"inline-block"} color={"red"} margin={"0px"} position={"relative"}>
                      <Text margin={"0px"} position={"absolute"} width={"60px"} border={"1px solid red"} bottom={"10px"} right={"0px"}></Text>
                      /${item.variant.original_price}</Text>) : null}
                  </Text>
                </Box>
                <Box className="add-to-cart-button-main">
              
              
                  <button
                    onClick={(e)=>addToCartHandler(item)}
                    style={{color: topbar?.top_bar_text_color, background: topbar?.top_bar_color}}
                    // onMouseEnter={}
                    // onMouseLeave={(e) => {e.target.style.backgroundColor = ''; }}
                    className="add-to-cart-button">{t("quick_buy")}</button>
                </Box>
              </div>
            </div>
          );
        })}
      </ItemsCarousel>
      <button style={{float: 'right'}} onClick={()=>navigate("/products/hooks/foryou")}>{t("see_all")}</button>
    </div>
  );
};
export default ChoosenForYou;
