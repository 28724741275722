import React, { useEffect, useState } from 'react';
import ProductService from "../../apis/product.api";
import UserService from "../../apis/user.api";
import { useSelector, useDispatch } from "react-redux";
import { loadUser } from "../../store/actions/productLike.action";
import { useTranslation } from 'react-i18next';
const BrandMenu = ({top_bar_color}) => {
    const { t } = useTranslation();
    const [groups, setGroups] = useState([]);
    const [reload, setReload] = useState(true);
    const dispatch = useDispatch();
    const productApiService = new ProductService();
    const userApiService = new UserService();
    const userId = localStorage.getItem("userId");

    const fetchData = async () => {
        setReload(false);
        const res = await productApiService.getBrands();
        setGroups(res);
        
    }

    const fetchUser = async () => {
        if(userId){
            const res = await userApiService.getCurrentUser();
            dispatch(loadUser(res));
        }
    }
    const fetchLikedProducts = async () => {
        if(localStorage.getItem("token")){
            try {
                const res = await productApiService.getLikedProductIds();
                dispatch({type: "LIKE_PRODUCT_LIST", payload: res.product_ids});
            }
            catch(err) {
                dispatch({type: "LIKE_PRODUCT_LIST", payload: []});
            }
        }
    }

    useEffect(() => {
        if(reload){
            fetchUser();
            fetchData();
            fetchLikedProducts();
        }
      },[])
      
    return (
        <li>
            
            <a href="/brands-list"
            style={{textTransform: "capitalize"}}
                onMouseEnter={(e)=>{e.target.style.color = top_bar_color}}
                onMouseLeave={(e)=>{e.target.style.color = ""}}
                className='nav5-li-link'>{t("brands")} {/*  <span className="arrow-up"></span>*/}</a> 
            <div>
                <div>
                    {groups.map((group, index) => {
                        return (
                            <ul key={index}>
                                {index == 0 ?
                                    <li>
                                        <a href="/brands-list" className="hover-item-li">{t("all_brands")}</a>
                                    </li>
                                : null}
                                <li>
                                    <a href="#" className="hover-item-li">{group.name}</a>
                                </li>
                                {group.brands.map((brand,idx) => {
                                    return (
                                        <li key={idx+index}>
                                        <a href={`/products/brand/${brand.slug}`}>
                                            {brand.name}
                                        </a>
                                        </li>
                                    )
                                })}
                            </ul>
                    
                    )})} 
                </div>
               
            </div>
        </li>
    )
};

export default BrandMenu;