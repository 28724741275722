import {
  Box,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

const OrderProductList = ({ order_items, shipping, tax, total_price }) => {
  // const products = product ? [product] : [];
  return (
    <Box marginTop="30px">
      <Heading size={"sm"}>Products</Heading>
      <TableContainer marginTop="20px">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Variant</Th>
              <Th>Price</Th>
            </Tr>
          </Thead>
          <Tbody>
            {order_items.map((p, idx) => (
              <Tr key={idx}>
                <Td display="flex" alignItems="center">
                  <img src={p.variant.main_image} height="50" width="50" />
                  <Box marginLeft={'20px'}>
                    <Box fontSize="12px" fontWeight="bold">
                      {p.product.brand}
                    </Box>
                    <div>{p.product.name}</div>
                  </Box>
                </Td>
                <Td>
                  <Box>SKU: {p.variant.sku}</Box>
                  <Box>Size: {p.variant.size}</Box>
                  <Box>Color: {p.variant.color}</Box>
                </Td>
                <Td>${p.price}</Td>
              </Tr>
            ))}
          </Tbody>

          <Tfoot>
            <Tr bgColor="">
              <Th color="">Tax</Th>
              <Th></Th>

              <Th color="">${tax}</Th>
            </Tr>
            <Tr bgColor="">
              <Th color="">Shipping</Th>

              <Th></Th>
              <Th color="">${shipping}</Th>
            </Tr>
            <Tr bgColor="black">
              <Th color="white">Total price</Th>

              <Th></Th>
              <Th color="white">${total_price}</Th>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OrderProductList;
