import { Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState} from 'react'
import ItemsCarousel from "react-items-carousel";
import "./CarouselSection1.css";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ProductService from "../../../apis/product.api";

const CarouselSection1 = () => {
  const { t } = useTranslation();
  const [items, setBanners] = useState([]);
  const [reload, setReload] = useState(true);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 8;
  const lang = useSelector((state) => state.siteLanguage).lang;

  const productApiService = new ProductService();

  const getData = async () => {
    const res = await productApiService.getBanners();
    setBanners(res)
    setReload(false);
  }
  useEffect(() => {
    // if(reload){
      getData();
    // }
  }, [lang]);


  return (
    <div
      style={{ padding: `${chevronWidth}px 2px` }}
      className="card_container"
    >
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={1}
        gutter={8}
        leftChevron={
          <div className="carousel_btn_box_L">

            <ChevronLeftIcon boxSize={10} color="white" />
          </div>
        }
        rightChevron={
          <div className="carousel_btn_box_R">

            <ChevronRightIcon boxSize={10} color="white" />
          </div>
        }
        outsideChevron
        chevronWidth={chevronWidth}
      >
        {items.map((item, index) => {
          return (
            <Link to={item.target} key={index}>
              <div className="card_item" key={item.image}>
                <div className="card_item_img_box">
                  <img alt="test product" src={item.image} />
                </div>
                <div
                  style={{ backgroundColor: `${item.color}` }}
                  className="card_item_txt_box"
                >
                  <Heading as="h4" size="md" mt="-5px" style={{textTransform: "capitalize"}}>
                    {item.heading}
                  </Heading>
                  <Text fontSize="sm" ml="0px" mt="5px">
                    <Link to={item.target}>{item.text}</Link>
                  </Text>
                  <button className="carousel-shop-button">
                    <Link to={item.target}>{t("shop_now")}▸</Link>
                  </button>
                </div>
              </div>
             </Link>
          );
        })}
      </ItemsCarousel>
    </div>
  );
};

export default CarouselSection1;
