
import { 
    Box,  
    Input,
    Button,
    FormControl,
    FormLabel,
    VStack,
    Text } from "@chakra-ui/react";
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Stack,
  Flex,
  Checkbox,
  Radio,
  RadioGroup
} from '@chakra-ui/react'
import React, { useEffect,useContext, useState } from "react";
import { Link,useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import "./Checkout.css";
import userApiServices from "../../apis/user.api";
import geoapifyService from "../../apis/geoapify.api";

const ShippingAddress = ({setShippingAddressState,shippingAddressState, setActiveStep}) => {
    const { t } = useTranslation();
    const userServices = new userApiServices();
    const geoapifyApiServices = new geoapifyService();
    const defaultState = {
        first_name: "",
        last_name: "",
        address: "",
        city: null,
        State: "",
        postalCode: "",
        country: "",
        default:false
      }
    const [allShippingAddress, setAllShippingAddress] = useState(undefined);
    const [addNewFormState, setAddNewFormState] = useState(false);
    // const [orderShippingAddress, setOrderShippingAddress] = useState(0);
    const [shippingAddress, setShippingAddress] = useState(defaultState);
    // const { handleSubmit, control, errors, reset  } = useForm({defaultValues: shippingAddress});
    const { control, handleSubmit, reset, formState: { errors } } = useForm();
    const [geoAddressState, setGeoAddressState] = useState(false);
    const [geoAddressFeatures, setGeoAddressFeatures] = useState([]);

    const fetchData = async () => {
      const result = await userServices.getShippingAddress();
      setAllShippingAddress(result);
   }

    const onSubmit = async (data) => {
        const result = await userServices.createShippingAddress(data);
        fetchData();
        reset(defaultState);
        setShippingAddressState(`${result.id}`);
        setAddNewFormState(false);
      };

      useEffect(() => {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       

        fetchData();
    
      }, []);

    const handleRadioChange = (id) => {
      setShippingAddress(id);
    }

    const handleGeoaddressSelect = (address) => {
      const defaultState = {
        city: address.city,
        address: address.address_line2,
        state: address.state,
        postalCode: address.postcode,
        country:address.country,
        default:false
      }
      reset(defaultState);
      setGeoAddressState(true);
      setGeoAddressFeatures([]);
    }
   
    const handleAddressInputChange = async (e) => {
      let text = e.target.value;
      if(text !== ""){
        const res = await geoapifyApiServices.getAddressDetails(text);
        setGeoAddressFeatures(res.features);
      }else{
        setGeoAddressFeatures([]);  
      }

    }

      
return (
    <Box className="input-box">
    <Text className="c-head">{t("checkout") }</Text>
    <hr style={{ border: "1px solid black" }} />
    <Text fontSize={"1.4rem"} fontWeight={800}>
    {t("shipping_address")}
    </Text>
    <Box className="shipping-main-flex" width={"100%"}>
        <Box marginTop={"20px"} className="shipping-address-main">
          {addNewFormState ?

      geoAddressState ?
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={4}>
      <Flex justify="space-between" width={"100%"}>
      <FormControl isInvalid={errors.first_name} marginRight={"10px"}>
          <FormLabel htmlFor="first_name">{t("first_name")}</FormLabel>
          <Controller
            name="first_name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Enter your first name"
              />
            )}
            rules={{ required: true }}
          />
        </FormControl>
        <FormControl isInvalid={errors.last_name}>
          <FormLabel htmlFor="last_name">{t("last_name")}</FormLabel>
          <Controller
            name="last_name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Enter your last name"
              />
            )}
            rules={{ required: true }}
          />
        </FormControl>
    </Flex>
        
      </VStack>

      <VStack spacing={4}>
        <FormControl isInvalid={errors.address}>
          <FormLabel htmlFor="address">Address</FormLabel>
          <Controller
            name="address"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Enter your address"
              />
            )}
            rules={{ required: true }}
          />
        </FormControl>
      </VStack>
      <VStack spacing={4}>
        <FormControl isInvalid={errors.city}>
          <FormLabel htmlFor="city">City</FormLabel>
          <Controller
            name="city"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Enter your city"
              />
            )}
            rules={{ required: true }}
          />
        </FormControl>
      </VStack>
      <VStack spacing={4}>
        <FormControl isInvalid={errors.state}>
          <FormLabel htmlFor="state">State</FormLabel>
          <Controller
            name="state"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Enter your state"
              />
            )}
            rules={{ required: true }}
          />
        </FormControl>
      </VStack>
      <VStack spacing={4}>
        <FormControl isInvalid={errors.postalCode}>
          <FormLabel htmlFor="postalCode">Postal Code</FormLabel>
          <Controller
            name="postalCode"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Enter your postalCode"
              />
            )}
            rules={{ required: true }}
          />
        </FormControl>
      </VStack>
      <VStack spacing={4}>
        <FormControl isInvalid={errors.country}>
          <FormLabel htmlFor="country">Country</FormLabel>
          <Controller
            name="country"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Enter your country"
              />
            )}
            rules={{ required: true }}
          />
        </FormControl>
      </VStack>
      <VStack spacing={4}>

      <FormControl textAlign={"left"} marginTop={"10px"}>
          <Controller
            name="default"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Checkbox {...field}>Default</Checkbox>
            )}
          />
        </FormControl>
      </VStack>
     
      <Button variant="outline" colorScheme='teal' type="submit">Submit</Button>
    </form> : <Box className="address-autocomplete-main" position={'relative'}><Input
                onChange={handleAddressInputChange}
                placeholder="Search address"/>
                  <Box className={`${geoAddressFeatures.length > 0 ? "auto-complete-address-popup" : ""}`}position={'absolute'}>
                    {geoAddressFeatures.map((item,idx) => {
                      return (
                        <Box className="auto-complete-address-list" cursor={'pointer'} key={idx} onClick={(e) =>handleGeoaddressSelect(item.properties)}>
                        <Text>{item.properties.formatted}</Text>
                      </Box>
                      )
                    })}
                  
                  </Box> 
              </Box>
            :   <Box textAlign={"center"} className="shipping-address-list-div" textDecoration={"underline"} color={'blue'}>
                <a href="javascript:void(0)" onClick={()=>setAddNewFormState(prevCheck => !prevCheck)}>{t("add_new")} </a>

                </Box>}
    </Box>
    <Box marginTop={"20px"} className="shipping-address-list-main"> 
    <RadioGroup onChange={setShippingAddressState} value={shippingAddressState}>
        {allShippingAddress?.map((item,idx) =>{
            return (
                <Box key={idx} textAlign={"left"} className="shipping-address-list-div">
                    <Radio value={`${item.id}`}>
                    <Text>{item.first_name} {item.last_name}</Text>
                    <Text>{item.address}</Text>
                    <Text>{item.city}, {item.state}</Text>
                    <Text>{item.postalCode}, {item.country}</Text>
                    </Radio>

                </Box>
            )
        })}
    </RadioGroup>
  
    <Button  onClick={(e) => setActiveStep(1)} variant="outline" colorScheme='teal' isDisabled = {shippingAddressState ? false : true}>{t("next")}</Button>
    </Box>
    </Box>
  </Box>
)

    }

export default ShippingAddress;