import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { 
    Box,  
    Input,
    Button,
    FormControl,
    FormLabel,
    VStack,
    Text,
    Flex,
    RadioGroup,
    Radio,
    Stack,
    Card,
    CardHeader,
    CardBody,
    Heading } from "@chakra-ui/react";
import ItemsCarousel from "react-items-carousel";

import {
      ChevronRightIcon,
      ChevronLeftIcon,
    } from "@chakra-ui/icons";
import { useDisclosure } from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import PostReviewForm from "./PostReviewForm";
// import io from "socket.io-client";

// const socket = io.connect("http://localhost:8081");

function ReviewSection({product_reviews, id, fetchData, showReviewButtonState, handleShowAllReviews, handleShowLessReviews}) {
    const [activeItemIndexState, setActiveItemIndexState] = useState({});
    const [postReviewState, setPostReviewState] = useState(false);
    const chevronWidth = 8;
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [itemNumber, setItemNumber] = useState(5);

    useEffect(() => {
      const windowWidth = window.innerWidth;
      var numberOfCards = 5;
      if (windowWidth < 640) {
        numberOfCards = 3;
      }
      setItemNumber(numberOfCards);
    }, [window.innerWidth]);

    const setActiveItemIndex = (arg, index) => {
        setActiveItemIndexState((prev) => ({...prev, [index]:arg}))
    }

    const getItemIndex = (index) => {
        return activeItemIndexState[index] ? activeItemIndexState[index] : 0;
    }
    return (
        <div>
            <PostReviewForm isOpen={isOpen} onClose={onClose} id={id} fetchData={fetchData}/>
            <Card variant={'outline'}>
                <CardHeader borderBottom={'1px solid var(--chakra-colors-chakra-border-color)'}>
                    <Flex justifyContent={'space-between'}>
                        <Box display={'flex'} alignItems={'center'}><Text fontWeight={'600'} color={'#5c5757'}>{product_reviews.length} Reviews</Text></Box>
                        <Button colorScheme='teal' onClick={onOpen}>Post a review</Button>
                    </Flex>
                </CardHeader>
                {product_reviews.length > 0 ? ( 
                <>
                    <CardBody>
                        {product_reviews.map((review, index)=>{
                            return(
                                <Card key={index} marginBottom={'25px'} variant={'outline'} colorScheme={'gray'}>
                                    <CardHeader borderBottom={'1px solid var(--chakra-colors-chakra-border-color)'}>
                                        <Flex flexDirection={'row'}>
                                            <Box className="review-user-image">
                                                <img src="https://st4.depositphotos.com/4329009/19956/v/450/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg" />
                                            </Box>
                                            <Box marginLeft={'10px'}>
                                                <Heading size='x'>{review.pubic_name}</Heading>
                                                <Box display='flex' mt='2' alignItems='center'>
                                                    {Array(5)
                                                        .fill('')
                                                        .map((_, i) => (
                                                        <StarIcon
                                                            key={i}
                                                            color={i < review.rating ? 'teal.500' : 'gray.300'}
                                                        />
                                                        ))}
                                                    {/* <Box as='span' ml='2' color='gray.600' fontSize='sm'>
                                                        {5} reviews
                                                    </Box> */}
                                                </Box>
                                            </Box>
                                        </Flex>
                                    </CardHeader>
                                    <CardBody>
                                        <Heading size='x'>{review.headline}</Heading>
                                        <Text marginTop={'10px'}>
                                            {review.comment}
                                        </Text>
                                        {review.review_images.length > 0 ?(
                                        <Box marginTop={'30px'}>
                                        <Heading size='x'>Review Images</Heading>
                                        <Card variant={'outline'} padding={'20px'} marginTop={'10PX'}>
                                        
                                            <ItemsCarousel
                                                requestToChangeActive={(arg)=>setActiveItemIndex(arg, index)}
                                                activeItemIndex={getItemIndex(index)}
                                                numberOfCards={itemNumber}
                                                gutter={8}
                                                leftChevron={
                                                <div className="carousel_btn_box_L">
                                                    {" "}
                                                    <ChevronLeftIcon boxSize={10} color="white" />{" "}
                                                </div>
                                                }
                                                rightChevron={
                                                <div className="carousel_btn_box_R">
                                                    {" "}
                                                    <ChevronRightIcon boxSize={10} color="white" />{" "}
                                                </div>
                                                }
                                                outsideChevron
                                                chevronWidth={chevronWidth}
                                            >
                                                {review.review_images.map((item, index) => {
                                                    return (
                                                        <div className="carousel-item-main" key={index}>
                                                            <img alt="test product" src={item.image} />
                                                        </div>
                                                    );
                                                })}
                                            </ItemsCarousel>
                                            </Card>
                                        </Box>) : null}
                                    </CardBody>
                                </Card>
                            )
                        })}
                    </CardBody>
                    {product_reviews.length > 3 ? (
                        showReviewButtonState ? (
                                <Button colorScheme="teal" onClick={()=>handleShowLessReviews()}>Show Less</Button>
                            ) : (
                                <Button onClick={()=>handleShowAllReviews()}>Show All</Button>
                            )
                        )
                    : null}  </> ): null}
            </Card>
        </div>
    );
}

export default ReviewSection;
