import React, { useEffect } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Image,
  SimpleGrid,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux"
import "./MyOrder.css";
import { useTranslation } from 'react-i18next';
import OrderService from "../../apis/order.api";
import ProductService from "../../apis/product.api";
const MyOrder = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ApiOrdersService = new OrderService();
  const apiProductService = new ProductService();
  const [orderList, setOrderList] = useState();
  const [isData, setIsData] = useState(false);

  const [item, setitem] = useState();

  const [orders, setOrders] = useState([]);

  const [product, setProduct] = useState([]);

  const [detail, setDetails] = useState();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBox, setSelectedBox] = useState({});

  const handleClick = (detail) => {
    setIsModalVisible(true);
    setSelectedBox(detail);
  };

  const fetchData = async () => {
    const results = await ApiOrdersService.getAllOrders();
    setOrders(results);
    const cartObj = await apiProductService.getCart();
    dispatch({type: 'UPDATE_CART',payload: cartObj});
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
    <div className="order-list-main" style={{marginBottom: "100px",minHeight:"64vh"}}>
    <h1>{t("my_orders")}</h1>
    <hr style={{ margin: "10px 0" }} />
    <Box>
      {orders.map((order,index) => {
        return (
          <Box key={index}>
          <Box direction="row" className="my-order-list-item">
         
            <Box width="5%">
              <img src={order.order_items[0].variant.main_image}  className="wishlist-page-image"/>
            </Box>
            <Box width="30%" className="wishklist-product-name">
              <a href={`/orders/${order.id}`}>
                <Text fontWeight="bold">{order.order_items[0].product.brand}</Text>
                <Text >{order.order_items[0].product.name}</Text>
              </a>
             
            </Box>
            <Box width="10%">
            <Text fontWeight="bold">${order.total_price}</Text>
            </Box>
            {/* <Box width="20%">
              <Text>Size: {order.variant.size}</Text>
              <Text>Color: {order.variant.color}</Text>
            </Box> */}
            
          <Box width="20%">
           <Text>{order.order_shipping_address?.first_name} {order.order_shipping_address?.last_name}</Text>
           <Text>{order.order_shipping_address?.address}</Text>
           <Text>{order.order_shipping_address?.city},{order.order_shipping_address?.state},{order.order_shipping_address?.postalCode}</Text>
          </Box><Box width="10%">
           
           {order.status}
       </Box>
       <Box width="15%">
            <Text>{order.order_time}</Text>
            <Text>{order.order_created}</Text>
          </Box>
          </Box>
          <hr style={{ margin: "10px 0" }} />
          </Box>
          
        )
        
      })}
     
    </Box>
    </div>
  </>
  );
};

export default MyOrder;
