import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { 
    Box,  
    Input,
    Button,
    FormControl,
    FormLabel,
    VStack,
    Text,
    Flex,
    Textarea,
    HStack,
    useColorModeValue,
    FormErrorMessage,
    Stack,
    Card,
    CardHeader,
    CardBody,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton } from "@chakra-ui/react";
import ItemsCarousel from "react-items-carousel";
import {
      ChevronRightIcon,
      ChevronLeftIcon,
    } from "@chakra-ui/icons";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { StarIcon } from '@chakra-ui/icons';
import { useForm } from "react-hook-form";
import ProductService from "../../apis/product.api";
import Dropzone from 'react-dropzone'
import StarRating from "./StarRating";

const closeImage = require("./cross-svgrepo-com.png");
const loading_icon = require("./loading-icon.gif");
// const socket = io.connect("http://localhost:8081");

function PostReviewForm({isOpen, onClose, id, fetchData}) {

    const { t } = useTranslation();
    const {
        handleSubmit,
        reset,
        register,
        formState: { errors, isSubmitting },
        getValues
      } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [upoloadedImages, setUpoloadedImages] = useState([]);
    const [rating, setRating] = useState(0);
    console.log(rating);
    const apiProductService = new ProductService();

      const onSubmit = async (data) => {
        setIsLoading(true);
        try {
          const userData = new FormData();
          userData.append("public_name",data.public_name)
          userData.append("product_id",id)
          userData.append("headline",data.headline)
          userData.append("comment",data.comment)
          userData.append("rating",rating)
          upoloadedImages.forEach((file, index) => {
            userData.append(`files`, file);
          });
          const res = await apiProductService.createProductReview(userData);
          toast.success(t("contact_page_thanks_message"), {
            position: "top-right", // You can adjust the position
            autoClose: 3000, // Time in milliseconds for the toast to auto close
          });
          fetchData();
      } catch(error) {
          toast.error(t("internal_server_error"), {
            position: "top-right", // You can adjust the position
            autoClose: 3000, // Time in milliseconds for the toast to auto close
          });
      }
      reset();
      onClose();
      setIsLoading(false);
    }
       

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Rating & Reviews</ModalHeader>
                <ModalCloseButton onClick={()=> reset()}/>
                <hr />
                <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="star-ratting-main">
                        <StarRating rating={rating} setRating={setRating} size={30}/>
                    </div>
                    <Stack spacing={4}>
                        <>
                        <HStack>
                        <Box>
                            <FormControl id="public_name" isInvalid={errors.first_name} isRequired>
                            <FormLabel htmlFor="public_name">{t("public_name")}</FormLabel>
                            <Input
                                id="public_name"
                                placeholder={t("public_name")}
                                {...register('public_name', {
                                required: 'This is required',
                                minLength: { value: 4, message: 'Minimum length should be 4' },
                                })}
                            />
                            <FormErrorMessage>
                                {errors.public_name && errors.public_name.message}
                            </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl id="headline" isInvalid={errors.headline} isRequired>
                            <FormLabel htmlFor="headline">{t("headline")}</FormLabel>
                                <Input
                                id="headline"
                                placeholder={t("headline")}
                                {...register('headline', {
                                required: 'This is required',
                                minLength: { value: 4, message: 'Minimum length should be 4' },
                                })}
                            />
                            <FormErrorMessage>
                                {errors.headline && errors.headline.message}
                            </FormErrorMessage>
                            </FormControl>
                        </Box>
                        </HStack>
                       
                        <FormControl id="comment" isInvalid={errors.message} isRequired>
                        <FormLabel htmlFor="comment">{t("comment")}</FormLabel>
                        <Textarea
                                id="comment"
                                placeholder={t("comment")}
                                {...register('comment', {
                                required: 'This is required',
                                })}
                            />
                            <FormErrorMessage>
                                {errors.comment && errors.comment.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl id="comment" isInvalid={errors.message}>
                        <FormLabel htmlFor="comment">{t("images")}</FormLabel>
                        <Dropzone onDrop={acceptedFiles => setUpoloadedImages( prevState => [...prevState, ...acceptedFiles])}>
                            {({getRootProps, getInputProps}) => (
                                <section className="dropzone-section">
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {upoloadedImages.length > 0 ? (
                                        <ol>
                                            {upoloadedImages.map((item,idx) => {
                                                return (
                                                    <li key={idx}>{item.name}</li>
                                                )
                                            })}
                                        </ol>
                                    ) : (
                                    <p>Drag 'n' drop some files here, or click to select files</p> ) }
                                </div>
                                </section>
                            )}
                            </Dropzone>
                            </FormControl>
                        </>
                        
                    
                        <Stack spacing={10} pt={2}>
                        {isLoading ? (
                            <Button
                            type="button"
                            loadingText="Submitting"
                            size="lg"
                            bg={"black"}
                            color={"white"}
                            _hover={{
                                bg: "black",
                            }}
                            // isDisabled={true}
                            >
                            <img src={loading_icon} style={{width: "80px"}}/>
                            </Button>
                        ) : (
                            <Button
                            type="submit"
                            loadingText="Submitting"
                            size="lg"
                            bg={"black"}
                            color={"white"}
                            _hover={{
                                bg: "black",
                            }}
                            >
                            {t("submit_review")}
                        </Button>
                        ) }
                        
                        </Stack>
                    </Stack>
                    </form>
                </ModalBody>

                {/* <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                    Close
                    </Button>
                    <Button variant='ghost'>Secondary Action</Button>
                </ModalFooter> */}
                </ModalContent>
            </Modal>
        </>
    );
}

export default PostReviewForm;
