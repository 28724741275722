import axios from 'axios';
import React, { useContext, useEffect, useState} from 'react'
import "./BottomBanner.css";
import { Heading, Link, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import ProductService from "../../../apis/product.api"

const BottomBanner = () => {
  const lang = useSelector((state) => state.siteLanguage).lang;
  const [banners, setBanners] = useState([]);
  const [reload, setReload] = useState(true);
  const chevronWidth = 8;

  const productApiService = new ProductService();

  const getData = async () => {
    const res = await productApiService.getBanners();
    setBanners(res)
    setReload(false);
  }
  console.log(lang);
  useEffect(() => {
    console.log("test");
    getData();

  }, [lang]);

  return (
    <div className="bottom_container">
      <hr style={{ margin: "50px 0" }} />
      {banners.map((item) => (
      <div className="bottomBanner_container">
   
     
        <div className="bottomBanner_item">
          <div
            style={{ backgroundColor: "#c6a886" }}
            className="bottomBanner_item_txt_box"
          >
            <Heading as="h4" size="md" mt="-5px">
              {item.name}
            </Heading>
            <Text fontSize="sm" ml="0px" mt="5px">
              <Link to="#">
               {item.text}
              </Link>
            </Text>
            <button>
              <Link to="#">LEARN MORE▸</Link>
            </button>
          </div>
          <div className="bottomBanner_item_img_box">
            <img
              alt="test product"
              src={item.image}
            />
          </div>
        </div>
      </div>
        ))}
      <hr style={{ margin: "50px 0" }} />
    </div>
  );
};

export default BottomBanner;
