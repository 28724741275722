import {
  Box,
  Flex,
  Heading,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from "@chakra-ui/react";

const OrderStatusTracker = ({
  expected_delivery_date,
  order_created,
  order_time,
  status,
  is_delivered,
  status_updated_date,
  delivered_at,
  shopify_order_number,
  shopify_tracking_url
}) => {
  const steps = [
    { title: "Order created", description: "", extra: `${order_created}, ${order_time}` },
    { title: "Order On Hold", description: "", extra: status_updated_date  },
    { title: "Out for delivery", description: "", extra: status_updated_date  },
    { title: "Delivered", description: "", extra: delivered_at}
  ];
  const statusIndexMap = {
    created: 0,
    OnHold: 1,
    Processing: 2,
    Completed: 3
  };

  const { activeStep, setActiveStep } = useSteps({
    index: statusIndexMap[status],
    count: steps.length,
  });
  if (activeStep) steps[activeStep].description = steps[activeStep].extra;
  const max = steps.length - 1;

  return (
    <Flex
      direction="column"
      justifyContent="space-around"
      alignItems="center"
      wrap={"wrap"}
    >
      {status === "Cancelled"? 
        <Box marginTop="20px">
          {status === "Cancelled"? 
              <>
                <Heading size="md" color={'red'}>Cancelled!</Heading>
                <Heading size="md">Cancelled on {status_updated_date}</Heading>
              </> : null}
        </Box>
      : 

      <>
       <Stepper size="sm" index={activeStep} marginTop={"20px"} width={"80%"}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Box>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription margin={"0"}>{step.description}</StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper> 
      <Box marginTop="20px">
        <>
          <Heading size="md">Expected on {expected_delivery_date}</Heading>
          <Box><a href={shopify_tracking_url} style={{color: "#0000EE",textDecoration: "underline"}} target="_blank">Click here</a> To Track your order</Box>
          <Box>Order Number: {shopify_order_number}</Box>
        </> 
      </Box>
      </>
     
    }
      
    </Flex>
  );
};

export default OrderStatusTracker;
