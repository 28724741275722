import { VStack, Image, Box, Text, Heading, Button } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
const redHeart = require("./red-heart.png");
const proIcon = require("./pro.png");
export default function ProductCard({ image,name,slug, brand,price,rating,_id, is_liked, is_pro, handleLikeProduct }) {
//   const navigate=useNavigate()
  return (
    <>
     <Box className="product-card" shadow="md" borderRadius="md">
      {is_pro ? (<Box className="product-card-pro-icon">
        <img src={proIcon} />
      </Box> ) : null }
      <button className="product-card-like-button" onClick={(e)=> handleLikeProduct(_id)}>
        {is_liked ?
         <img src={redHeart} alt="like"/> :
      <><svg viewBox="0 0 24 24" aria-hidden="true" data-comp="Icon StyledComponent "><path d="M22 3.1c2.7 2.2 2.6 7.2.1 9.7-2.2 2.8-7.4 8.1-9.3 9.6-.5.4-1.1.4-1.6 0-1.8-1.5-7-6.8-9.2-9.6-2.6-2.6-2.7-7.6 0-9.7C4.6.5 9.7.7 12 4.2 14.3.8 19.3.5 22 3.1zm-.7.8c-2.4-2.4-7.2-2-8.9 1.5-.1.3-.4.4-.7.2-.1 0-.2-.1-.2-.2-1.6-3.5-6.5-4-8.9-1.5C.4 5.6.5 10 2.7 12.2c2.2 2.7 7.3 8 9.1 9.4.1.1.2.1.3 0 1.8-1.4 6.9-6.7 9.1-9.5 2.3-2.1 2.4-6.5.1-8.2z"></path></svg> </>
  }
      </button>
      <Link to={`/product/${slug}`} >
            <Image
              src={image}
              alt={name}
              w="100%"
              className="product-card-image"
              borderRadius="md"
            />
          <Text className="product-card-brand" fontSize="18px" fontWeight={"bold"} pl={1}>
            {brand}
          </Text>
          <Text className="product-card-name" color={"black"} fontSize={"20px"} fontFamily={"inherit"} pl={1}>{name}</Text>
          {/* <Text color={"black"} backgroundColor={"orange.200"} w={"100%"} p="2"> colors</Text> */}
          {/* <Text fontWeight={"bold"} fontSize={"20px"} color={"tomato"} w={"100%"}>{rating}☆</Text> */}
          <Text className="product-card-price" fontWeight={"bold"} fontSize={"23px"} pl={1}>${price}</Text>
          
        
        
        </Link>
        </Box>
    </>
  );
}