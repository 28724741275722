import React, { useEffect } from "react";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { FcGoogle, FcPhoneAndroid } from "react-icons/fc";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  Center,
  useColorModeValue,
} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { useState } from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";
import UserService from "../../apis/user.api";
import Dashboard from "../Dashboard";
import GoogleLoginCopm from "./GoogleLogin";

export default function Login({  history }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const userService = new UserService();
 
  
   
  const submitHandler =  async (e) => {
    e.preventDefault();

    try {
        const data = await userService.login({email,password});
        localStorage.setItem("userId", data.id);
        localStorage.setItem("token", data.token);
        toast.success(t("logged_in"), {
          position: "top-right", // You can adjust the position
          autoClose: 3000, // Time in milliseconds for the toast to auto close
        });
        navigate('/');

    } catch(error) {
        toast.error(t("login_failed"), {
          position: "top-right", // You can adjust the position
          autoClose: 3000, // Time in milliseconds for the toast to auto close
        });
        //console.log("Invalid Email or Password");
    }
}


useEffect(() => {
  if(localStorage.getItem("token")) {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("role");
  }
})

const handleGoogleLogin = async (response) => {
  try {
    const data = await userService.loginWithGoogle({access_token:response.access_token}, "google-oauth2");
    localStorage.setItem("userId", data.id);
    localStorage.setItem("token", data.token);
    localStorage.setItem("role", data.user_type);
    toast.success("You have successfully logged in!", {
      position: "top-right", // You can adjust the position
      autoClose: 3000, // Time in milliseconds for the toast to auto close
    });
    navigate('/');
  } catch(error) {
    alert("Invalid Email or Password!");
    //console.log("Invalid Email or Password");
  }
};


  return (
    <div>
      <Flex
        minH={"100vh"}
        align={"center"}
        justify={"center"}
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"}>{t("sign_account")}</Heading>
          </Stack>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
          >
            <form method="POST" onSubmit={submitHandler} className="login-form">
              <Stack spacing={4}>
                <FormControl id="email">
                  <FormLabel>{t("email_address")}</FormLabel>
                  <Input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                  />
                </FormControl>
                <FormControl id="password">
                  <FormLabel>{t("")}</FormLabel>
                  <Input
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                  />
                </FormControl>
                <Stack spacing={10}>
                  <Stack
                    direction={{ base: "column", sm: "row" }}
                    align={"start"}
                    justify={"space-between"}
                  >
                    {/* <Checkbox>Remember me</Checkbox> */}
                    <Link color={"black"}>{t("forgot_password")}?</Link>
                  </Stack>
                  <Button
                    bg={"black"}
                    color={"white"}
                    type="submit"
                    _hover={{
                      bg: "pink",
                    }}
                  >
                    {t("signin")}
                  </Button>
                </Stack>
              </Stack>
              <Center>or</Center>
              <Center>
                <GoogleLoginCopm handleGoogleLogin={handleGoogleLogin}/>
              </Center>
              <Link to="/phonelogin">
                <Center mt={2}>
                  <Button
                    w={"full"}
                    maxW={"md"}
                    colorScheme={"green"}
                    leftIcon={<FcPhoneAndroid />}
                  >
                    <Center>
                      <Text>{t("sign_in_with_phone")}</Text>
                    </Center>
                  </Button>
                </Center>
              </Link>
              <Center py={2}>
                {t("don_t_have_acc")}?
                <Link to="/signup">
                  {" "}
                  <Text as="u">{t("signup")}</Text>
                </Link>{" "}
              </Center>
            </form>
          </Box>
        </Stack>
      </Flex>
    </div>
  );
}
