import { Heading, Link, Text, Box } from "@chakra-ui/react";
import axios from 'axios';
import React, { useContext, useEffect, useState} from 'react';
import { useSelector } from "react-redux";
import ItemsCarousel from "react-items-carousel";
import { useNavigate } from "react-router-dom";
import "./Categories.css";
import {
  ChevronRightIcon,
  AddIcon,
  WarningIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";
import { useTranslation } from 'react-i18next';
import ProductService from "../../../apis/product.api";
const placeholder = require("./placeholder.jpg");
const JustDropped = () => {
  const { t } = useTranslation();
  const topbar = useSelector((state) => state.topBar).topbar;

  const [categories, setCategories] = useState([]);
  const [reload, setReload] = useState(true);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [itemNumber, setItemNumber] = useState(6);
  const chevronWidth = 8;
  const navigate = useNavigate();
  
  const productApiService = new ProductService();


  const getData = async () => {
    const res = await productApiService.getFeaturedCategories();
    setCategories(res)
    setReload(false);
  }
  useEffect(() => {
    if(reload){
      getData();
    }
  }, []);

  useEffect(() => {
    const windowWidth = window.innerWidth;
    var numberOfCards = 6;
    if (windowWidth < 640) {
      numberOfCards = 4;
    }
    setItemNumber(numberOfCards);
  }, [window.innerWidth]);

  return (
    <div
      // style={{ padding: `${chevronWidth}px 2px` }}
      className="dropped_container categories"
    >
      <Heading as="h4" size="md" mb="30px">
      {t("featured_categories")}{" "}
      </Heading>

      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={itemNumber}
        gutter={8}
        leftChevron={
          <div className="dropped_carousel_btn_box_L">
            {" "}
            <ChevronLeftIcon boxSize={10} color="white" />{" "}
          </div>
        }
        rightChevron={
          <div className="dropped_carousel_btn_box_R">
            {" "}
            <ChevronRightIcon boxSize={10} color="white" />{" "}
          </div>
        }
        outsideChevron
        chevronWidth={chevronWidth}
      >
        {categories.map((item, index) => {
          return (
            // <div className="dropped_item" key={index}>
            <>
              <div className="category_item_img_box">
                <img alt="test product" src={item.avatar !== "" ? item.avatar  : placeholder} />
               
              </div>
              <div
                style={{ backgroundColor: `#fff`, textAlign: "center" }}
                className="dropped_item_txt_box"
              >
                <a href={`/products/category/${item.slug}`} className="hover-item-li">
                  <Heading
                    onMouseEnter={(e)=>{e.target.style.color = topbar.top_bar_color}}
                    onMouseLeave={(e)=>{e.target.style.color = ""}}
                  as="h6" size="xs" mt="-5px">
                    {item.name}
                  </Heading>
                </a>
              </div>
              </>
          );
        })}
      </ItemsCarousel>
    </div>
  );
};
export default JustDropped;
