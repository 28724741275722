import axios from 'axios';
import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import {useNavigate, Link, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  color,
  Flex,
  Image,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
  useMediaQuery,
  useToast,FormControl,
  Grid
} from "@chakra-ui/react";

import {
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import ItemsCarousel from "react-items-carousel";
import AccordionComponent from "./AccordionComponent";

import ProductService from "../../apis/product.api";

import ReviewSection from "./ReviewSection";
import Subcategory from "./Subcategory";
import "./product.css";
const redHeart = require("./red-heart.png");

function SingleProduct() {
  const { t } = useTranslation();
  const lang = useSelector((state) => state.siteLanguage).lang;

  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");
 
  const Navigate = useNavigate();

  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [reviewsState, setReviewsState] = useState([]);
  // const toast = useToast();

  const productLikeIds = useSelector((state) => state.productLike).likedProductIds;
  const dispatch = useDispatch();

  const productApiService = new ProductService();
    
  const [product, setProduct] = useState(undefined);
  const [variant, setVariant] = useState(undefined);
  const [showReviewButtonState, setShowReviewButtonState] = useState(false);

  const params = useParams();
  const {slug} = params;

  const chevronWidth = 8;

  const fetchData = async () => {
    const result = await productApiService.getSingleProduct(slug);
    const main_variant = result.product_variants.filter((vari)=>{return vari.is_main === true})[0]
    setProduct(result);
    setVariant(main_variant);
    setReviewsState(result.product_reviews.slice(0, 3));
  }

  const handleShowAllReviews = () => {
    setReviewsState(product.product_reviews);
    setShowReviewButtonState(true);
  }

  const handleShowLessReviews = () => {
    setReviewsState(product.product_reviews.slice(0, 3));
    setShowReviewButtonState(false);
  }

  useEffect(() => {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       


    fetchData();

  }, [slug,lang]);

  const addToCartHandler = async () => {
      const variant_object = await productApiService.getVariant(variant.sku);
      if(variant_object.quantity > 0){
        const payload = {
          "product_id": product.id,
          "variant_id": variant.id,
          "sku": variant.sku,
          "quantity": 1
        }
        
        const result = await productApiService.addToCart(payload);
        dispatch({type: 'UPDATE_CART',payload: result});
        toast.success(t("added_to_cart"), {
          position: "top-right", // You can adjust the position
          autoClose: 3000, // Time in milliseconds for the toast to auto close
        });
        // Navigate('/basket');
      }else{
        // alert('Sorry. Product is out of stock.');
        toast.error(t("out_off_stock"), {
          position: "top-right", // You can adjust the position
          autoClose: 3000, // Time in milliseconds for the toast to auto close
        });
      }
  };

  const addToWishHandler = async () => {
      dispatch({type: "LIKE_PRODUCT", payload: product.id});
      const data = {product_id: product.id}
      const res = await productApiService.likeUnlikeProduct(data);
  }
  
  //.................................Add to wishlist functionality...............................................//


  return (
    <div
    className="product_main"
  >
    <Stack>{product ? <>
      <Flex
        mt={5}
        justify={"space-evenly"}
        direction={isLargerThan600 ? "row" : "column"}
      >
        
        <Flex width={isLargerThan600 ? "45%" : "100%"} direction="row">
        <Box width="9%" style={{marginRight: "1%"}}>
            {variant.images.map((item, index) => {
                return (
                  <img 
                    className={`side-image-list ${activeItemIndex === index ? "active" : ""}`}
                    alt="test product" 
                    onClick={(e)=> setActiveItemIndex(index)} 
                    src={item.image} 
                  />
                );
              })}
        </Box>
        {/* <Box width="10%">
        </Box> */}
          <Box width="90%">
          
            <ItemsCarousel
              requestToChangeActive={setActiveItemIndex}
              activeItemIndex={activeItemIndex}
              numberOfCards={1}
              gutter={8}
              leftChevron={
                <div className="dropped_carousel_btn_box_L">
                  {" "}
                  <ChevronLeftIcon boxSize={10} color="white" />{" "}
                </div>
              }
              rightChevron={
                <div className="dropped_carousel_btn_box_R">
                  {" "}
                  <ChevronRightIcon boxSize={10} color="white" />{" "}
                </div>
              }
              outsideChevron
              chevronWidth={chevronWidth}
            >
              {variant.images.map((item, index) => {
                return (
                  <div className="product_image_item" key={item.image}>
                      <img alt="test product" src={item.image} />
                  </div>
                );
              })}
            </ItemsCarousel> 
          </Box>
        </Flex>
        <Box width={isLargerThan600 ? "45%" : "100%"} className='product-detail-main'>
          <Box >
            <Text as="b" fontSize="14px">
              {product.brand}
            </Text>
            <Text fontSize="md" style={{marginLeft: "0px"}}>test</Text>
            <Text style={{marginLeft: "0px"}} className='product-likes-main'>
            <img
              src="https://cdn-icons-png.flaticon.com/128/535/535234.png"
              alt=""
              className="single-product-likes search"
              
            />
            <Text style={{marginLeft: "0px"}} className='product-likes-count'>
            {product.likes}
            </Text>
           
            </Text>
            <Box direction="column" mt={5}>
              <Box>
                <Text as="b">${!variant ? "-" : variant.price}</Text>
              </Box>
              <Box>
                <Text style={{marginLeft: "0px"}}>Size: <b>{!variant ? "-" : variant.size}</b></Text>
              </Box>
              <Box>
                <Text style={{marginLeft: "0px"}}>Color: <b>{!variant ? "-" : variant.color}</b></Text>
              </Box>
            </Box>
            <Box display={"flex"} direction="row">
              <Box width="100%">
                {product.hooks.map((hook,idx) => {
                  return (
                    <Text className="product-labels">{hook.name}</Text>
                  )
                  
                })}
              </Box>
                 
            </Box>
            <Grid templateColumns='repeat(3, 1fr)' className='product-variants-main'>
              {product.product_variants.map((product_variant, index) => {
                return (
                  <Box className={`variant-box ${variant && variant.sku === product_variant.sku ? 'active' : ''}`} key={index} onClick={(e)=> setVariant(product_variant)}>
                  <Text style={{marginLeft: "0px"}}>{product_variant.size} / {product_variant.color}</Text>
                </Box>
                )
               
              })}
            </Grid >
          </Box>
          {/* <Box mt={5}>
            <Text as="b" fontSize="md">
              Get it Shipped
            </Text>
            <RadioGroup defaultValue="1">
              <Stack spacing={5} direction="column">
                <Radio mt={10} colorScheme="green" value="1">
                  Standard
                </Radio>
                <Radio colorScheme="red" value="2">
                  Same-Day DeliverySame-Day Delivery not available for your
                  location Change your location
                </Radio>
              </Stack>
              <Box mt={5}>
                <Box>
                  <Text as="b" fontSize="md">
                    Buy Online & Pick Up
                  </Text>
                </Box>
                <Box mt={10}>
                  <Radio colorScheme="red" value="3">
                    Select to see availability at stores near you
                  </Radio>
                </Box>
              </Box>
            </RadioGroup>
          </Box> */}
          <Flex mt={10} className='addtobasket-flex'>
            <Box>
              <Button
                onClick={addToCartHandler}
                size={"lg"}
                pl={20}
                pr={20}
                borderRadius={50}
                fontSize="14px"
                colorScheme="red"
              >
                {t("add_to_basket")}{" "}
              </Button>
            </Box>
            <Box ml={3} >
              <Image
              onClick={addToWishHandler}
                width={50}
                src={productLikeIds.includes(product.id) ? redHeart : "https://cdn-icons-png.flaticon.com/512/7777/7777990.png"}
                alt=""
                className="search"
              />

            </Box>
          </Flex>
        </Box>
        
       
        </Flex> 
      <Box className="padding-5" style={{ marginTop: "75px" }}>
        <Box ml={3}>
          <Text fontSize={"20px"} as="b">
          {t("description")}
          </Text>
        </Box>
        <Box ml={10} w="70%">
          <Text noOfLines={[1, 2, 3]}>{product.description}</Text>
        </Box>
      </Box>
      <Box className="padding-5" style={{ marginTop: "30px" }}>
        <Box ml={3}>
          <Text fontSize={"20px"} as="b">
          {t("about_the_product")}
          </Text>
        </Box>
        <Box>
          <Subcategory
              heading={"Category:"}
              summary={product.category?.name}
            />
            <Subcategory
              heading={"Sub Categories:"}
              summary={
                product.sub_categories.map((sub_category, index) => {
                  return (
                    sub_category.name+", "
                  )
                })
              }
            />
          <Subcategory
            heading={"Formulation:"}
            summary={product.formulation ? product.formulation.name : null}
          />
          <Subcategory heading={"Skin Concern:"} summary={
            product.skin_concern.map((skin_concern, index) => {
              return (
                skin_concern.name+", "
              )
            })
          } />
          <Subcategory heading={"Skin Type:"} summary={
            product.skin_type.map((skin_type, index) => {
              return (
                skin_type.name+", "
              )
            })
          } />
        </Box>
      </Box>
      <Box className="padding-5">
        <Box ml={3}>
          <AccordionComponent
            heading={t("ingredients")}
            subHeading={
              "All Ingredients:"
            }
            summary={product.ingredients}
          />
        </Box>
        <Box ml={3}>
          <AccordionComponent
            heading={t("how_to_use")}
            subHeading={"Suggested Usage:"}
            summary={product.usage}
          />
        </Box>
      </Box>
      <Box>
        <Box ml={3}>
          <Box>
            <Text className="padding-5" fontSize={"20px"} as="b">
              {t("rating_and_reviews")} ({product.product_reviews.length})
            </Text>
          </Box>
          <Box className="margin-5"> 
            <ReviewSection 
              product_reviews={reviewsState} 
              id={product.id} 
              fetchData={fetchData}
              showReviewButtonState={showReviewButtonState}
              handleShowAllReviews={handleShowAllReviews}
              handleShowLessReviews={handleShowLessReviews} /> </Box>
        </Box>
      </Box>
      {/* <Flex
        style={{ marginTop: "50px", marginLeft: "10px", marginBottom: "50px" }}
        ml={5}
        gap={15}
        justify={"flex-start"}
        direction={isLargerThan600 ? "row" : "column"}
      >
        <Box>
          <Image
            m={"auto"}
            src="https://tpc.googlesyndication.com/simgad/16160614607739331938"
          />
        </Box>
        <Box>
          <Image
            m={"auto"}
            src="https://tpc.googlesyndication.com/simgad/14177892550749632546"
          />
        </Box>
        <Box>
          <Image
            m={"auto"}
            src="https://tpc.googlesyndication.com/simgad/17810597195573312581"
          />
        </Box>
      </Flex> */}
      </>: null }
    </Stack>
    </div>
  );
  
}

export default SingleProduct;
