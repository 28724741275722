import React from "react";
import "./Cart.css";
import { AiOutlinePlusCircle, AiOutlineMinusCircle, AiOutlineDelete} from "react-icons/ai";
import { Box, Text, Button, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const CartItem = ({t, cart, handleDecreaseQty, handleIncreaseQty, handleDeleteFromCart}) => {

  const navigate = useNavigate();


const checkoutHandler = () => {
  navigate('/login?redirect=/shipping');
}

  return (
    <div>
      {cart && cart.items.length > 0 ?
      cart.items.map((item)=>{
        return (
<Box className="sh-l2">
        <Box className="cart-item-image"> 
          



          <Image src={item.variant.main_image} alt="img" />
        </Box>
        <Box className="sh-l2-1">
          <Box className="sh-l2-1-1">
            <Box>
              <Link className="sh-link" to="#">
                <Text fontWeight={600}>{item.product.brand}</Text>
                <Text>{item.product.name}</Text>
              </Link>
              <Text color={"gray"} fontSize={"0.8rem"}>
                {/* {item._id} */}
              </Text>
            </Box>
            <Box>
              <Text fontWeight={600}>${item.total_price}</Text>
            </Box>
          </Box>
          <Box className="sh-l2-1-2">
          <Box>
          <Text><span className="cart-item-attri"><label>{t("color")}:</label> {item.variant.color}</span> <span className="cart-item-attri"><label>{t("size")}:</label>{item.variant.size}</span></Text>
          </Box>
            <Box className="sh-btns">
            <Button onClick={(e)=>handleDecreaseQty(item.sku)}>
              < AiOutlineMinusCircle
                            
                            
                            /> </Button>
              <Text className="cart-item-quantity">{item.quantity}</Text>
              <Button onClick={(e)=>handleIncreaseQty(item.product.id,item.variant.id,item.sku)}>

             
              <AiOutlinePlusCircle
                            
                            
                            />
              </Button>
              
              {/* <Button size={"sm"} variant="link" color={"blue"}>
                Move to Loves
        </Button> */}
              <Button  onClick={(e)=> handleDeleteFromCart(item.sku)} className="delete-from-cart-button">
                <AiOutlineDelete />
              </Button>
            </Box>
            {/* <Box>
              <button className="change-btn">Change Method</button>
            </Box> */}
          </Box>
        </Box> 
       </Box>
        )
      }) : <Box className="sh-l2">
      <Box className="cart-item-image" style={{width: "30rem"}}> <Text style={{fontWeight: 700}}>Your basket is currently empty.</Text></Box></Box>}
      
    </div>
    // <div></div>
  );
};

export default CartItem;
