import React from "react";
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import {useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import BeautyOffers from "./beauty-offers/BeautyOffers";
import BottomBanner from "./bottom-banner/BottomBanner";
import CarouselSection1 from "./carousel-1/CarouselSection1";
import FeaturedCategories from "./featured-categories/FeaturedCategories";
import ChoosenForYou from "./for-you/ChoosenForYou";
import GuidanceCategories from "./guidance/Guidance";
import InsiderRewards from "./Insider-rewards/InsiderRewards";
import JustDropped from "./Just-dropped/JustDropped";
import OfferBanner from "./offers-banner/OfferBanner";
import SellingFast from "./selling-fast/SellingFast";
import Categories from "./categories/Categories";
import ProductService from "../../apis/product.api";




const Home = () => {
  const apiProductService = new ProductService();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { t } = useTranslation();
  const addToCartHandler = async (product) => {
    const variant_object = await apiProductService.getVariant(product.variant.sku);
    if(variant_object.quantity > 0){
      const payload = {
        "product_id": product.id,
        "variant_id": variant_object.id,
        "sku": variant_object.sku,
        "quantity": 1
      }
      
      const result = await apiProductService.addToCart(payload);
      dispatch({type: 'UPDATE_CART',payload: result});
      toast.success(t("added_to_cart"), {
        position: "top-right", // You can adjust the position
        autoClose: 3000, // Time in milliseconds for the toast to auto close
      });
      // Navigate('/basket');
    }else{
      // alert('Sorry. Product is out of stock.');
      toast.error(t("out_off_stock"), {
        position: "top-right", // You can adjust the position
        autoClose: 3000, // Time in milliseconds for the toast to auto close
      });
    }
  };

  return (
    <>
      <Navbar />
      <CarouselSection1 />
       <Categories />
     <ChoosenForYou addToCartHandler={addToCartHandler}/>
      <JustDropped addToCartHandler={addToCartHandler}/>
       <BeautyOffers />
      
      <SellingFast />
      <Footer />

      {/* <OfferBanner /> */}     
      {/* <InsiderRewards /> */}
      {/* <FeaturedCategories /> */}
      {/* <GuidanceCategories /> */}
      {/* <BottomBanner /> */}
    </>
  );
};

export default Home;
