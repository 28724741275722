import React from "react";
import { 
    Box,  
    Input,
    Button,
    FormControl,
    FormLabel,
    VStack,
    Text,
    Flex,
    RadioGroup,
    Radio } from "@chakra-ui/react";

const PaymentComplete = () => {


return (
    <Box marginTop={"20px"} width={"100%"} paddingTop={"100px"} paddingBottom={"100px"} className="payment-complete-main">
        <Box className="order-completed-main">
        <Box className="order-completed-text">
        <img src="https://cdn.filestackcontent.com/tQt3SQHUQHWqRjlitcIF" />
        <Text > Order created successfully.</Text>
        </Box>
        </Box>
    </Box>
 );
};
export default PaymentComplete;