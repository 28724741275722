import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { 
    Box,  
    Input,
    Button,
    FormControl,
    FormLabel,
    VStack,
    Text } from "@chakra-ui/react";
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Stack,
  Flex,
  Checkbox,
  Radio,
  RadioGroup
} from '@chakra-ui/react'
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { loadUser } from "../../store/actions/productLike.action";
import { toast } from 'react-toastify';
import userServices from "../../apis/user.api";

const AccountInfo = ({currentUser}) => {
    const { t } = useTranslation(); 
    const userApiService = new userServices();
    const dispatch = useDispatch();
    const { control, handleSubmit, watch, reset, formState: { errors } } = useForm({
        defaultValues: currentUser
    });
    const onSubmit = async (data) => {
      try {
        const result = await userApiService.changeAccountPassword(data);
        reset({});
        toast.success('Account password Chnaged successfully.', {
            position: "top-right",
            autoClose: 3000,
          });
        } catch (error) {
          
          for (const key of Object.keys(error.response.data)) {
            toast.error(`${key}:  ${error.response.data[key]}.`, {
              position: "top-right",
              autoClose: 3000,
            });
          }
        }
      };

return (
    <>
    <Box width={["100%","70%"]}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={4}>
        <FormControl isInvalid={errors.current_password} marginRight={"10px"}>
            <FormLabel htmlFor="current_password">{t("current_password")}</FormLabel>
            <Controller
              name="current_password"
              control={control}
              defaultValue=""

              render={({ field }) => (
                <Input
                  {...field}
                  type='password'
                  placeholder={t("current_password_placeholder")}
                />
              )}
              rules={{ required: true }}
            />
          </FormControl>
    </VStack>
    <VStack spacing={4}>
        <FormControl isInvalid={errors.new_password} marginRight={"10px"}>
            <FormLabel htmlFor="new_password">{t("new_password")}</FormLabel>
            <Controller
              name="new_password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  type='password'
                  placeholder={t("new_password_placeholder")}
                />
              )}
              rules={{ required: true}}
            />
          </FormControl>
    </VStack>
    <VStack spacing={4}>
        <FormControl isInvalid={errors.confirm_password} marginRight={"10px"}>
            <FormLabel htmlFor="confirm_password">{t("confirm_password")}</FormLabel>
            <Controller
              name="confirm_password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  type='password'
                  placeholder={t("confirm_password_placeholder")}
                  
                />
              )}
              rules={{ 
                required: true,
                validate: value => value === watch("new_password") || "The passwords do not match"
               }}
            />
            {errors.confirm_password && <Text color={"red"}> {errors.confirm_password.message}</Text>}
          </FormControl>
    </VStack>
     
            <Box padding={"10px 0px"} textAlign={"center"}>
            <Button type="submit" colorScheme='teal'>Save</Button> 
        </Box>
      
    </form>
  
    </Box>
        
    </>
)
}
export default AccountInfo