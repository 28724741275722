import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { 
    Box,  
    Input,
    Button,
    FormControl,
    FormLabel,
    VStack,
    Text } from "@chakra-ui/react";
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Stack,
  Flex,
  Checkbox,
  Radio,
  RadioGroup
} from '@chakra-ui/react'
import { Controller, useForm } from "react-hook-form";
import { loadUser } from "../../store/actions/productLike.action";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import userServices from "../../apis/user.api";

const AccountInfo = ({currentUser}) => {
  const { t } = useTranslation();
    const userApiService = new userServices();
    const dispatch = useDispatch();
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: currentUser
    });
    const [editInfo, setEditInfo] = useState(false);
    const onSubmit = async (data) => {
        const result = await userApiService.updateUserinfo(currentUser.id, data);
        reset(result);
        dispatch(loadUser(result));
        toast.success('Account information updated successfully.', {
            position: "top-right", // You can adjust the position
            autoClose: 3000, // Time in milliseconds for the toast to auto close
          });
          setEditInfo(false);
      };

return (
    <>
    <Box width={["100%","70%"]}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={4}>
      <Flex justify="space-between" width={"100%"}>
      <FormControl isInvalid={errors.first_name} marginRight={"10px"}>
          <FormLabel htmlFor="first_name">{t("first_name")}</FormLabel>
          <Controller
            name="first_name"
            control={control}
            defaultValue=""
        
            render={({ field }) => (
              <Input
                {...field}
                disabled={!editInfo}
                placeholder="Enter your first name"
                className="account-info-input"
              />
            )}
            rules={{ required: true }}
          />
        </FormControl>
        <FormControl isInvalid={errors.last_name}>
          <FormLabel htmlFor="last_name">{t("last_name")}</FormLabel>
          <Controller
            name="last_name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                disabled={!editInfo}
                placeholder="Enter your last name"
                className="account-info-input"
              />
            )}
            rules={{ required: true }}
          />
        </FormControl>
    </Flex>
        
      </VStack>

      <VStack spacing={4}>
        <FormControl isInvalid={errors.phone_number}>
          <FormLabel htmlFor="phone_number">{t("phone_number")}</FormLabel>
          <Controller
            name="phone_number"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                disabled={!editInfo}
                placeholder="Enter your Phone Number"
                className="account-info-input"
              />
            )}
            rules={{ required: true }}
          />
        </FormControl>
    
        <FormControl isInvalid={errors.date_of_birth}>
          <FormLabel htmlFor="date_of_birth">{t("date_of_birth")}</FormLabel>
          <Controller
            name="date_of_birth"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                {...field}
                type="date"
                disabled={!editInfo}
                placeholder="Enter your Date Of Birth"
                className="account-info-input"
              />
            )}
            rules={{ required: true }}
          />
        </FormControl>
      </VStack>
        {editInfo ? 
            <Box padding={"10px 0px"} textAlign={"center"}>
            <Button type="submit" colorScheme='teal'>Save</Button> 
        </Box>: null}
      
    </form>
    {!editInfo ? 
        <Box padding={"10px 0px"} textAlign={"center"}>
            <Button type="button" colorScheme='teal' onClick={(e)=> setEditInfo(true)}>Edit</Button>
        </Box>: null}
    </Box>
        
    </>
)
}
export default AccountInfo