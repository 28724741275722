import { Box, Input, SimpleGrid, Text } from "@chakra-ui/react";
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Stack,
  Flex
} from '@chakra-ui/react'
import React, { useEffect,useContext, useState } from "react";
import { Link,useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from "@stripe/stripe-js/pure";
import { STRIPE_PUBLICK_KEY } from "../../config";
import "./Checkout.css";
import Place from "./Place";
import ShippingAddress from "./ShippingAddress";
import PaymentComplete from "./PaymentComplete";
import CheckoutForm from "./CheckoutForm";
import ConfirmPayment from "./ConfirmPayment";
const stripePromise = loadStripe(STRIPE_PUBLICK_KEY);

const Checkout = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [shippingAddressState, setShippingAddressState] = useState(undefined);
  const [paymentMethodState, setPaymentMethodState] = useState(undefined);
  const [clientSecret, setClientSecret] = useState('');

  const [stepsState, setStepsState] = useState(0);
  // const [activeStep, setActiveStep] = useState(1);
  const steps = [
    { title: t("shipping_address"), description: t("shipping_address") },
    { title: t("payment"), description: t("payment") },
    { title: t("completed"), description: t("order_completed") },
  ]

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  })

return (
  <Elements stripe={stripePromise}>
    

  <div
  className="product_main"
  style={{minHeight:"64vh"}}
>
  <Stack>
    <Flex
      mt={5}
      justify={"space-evenly"}
      direction="row"
    >
    <Box className="head-check" padding={"1.2rem"}>
       <Stepper index={activeStep} className="checkout-main-stepper">
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>

          <Box flexShrink='0'>
            <StepTitle>{step.title}</StepTitle>
            <StepDescription>{step.description}</StepDescription>
          </Box>

          <StepSeparator />
        </Step>
      ))}
    </Stepper>

       
        <Box>
            {activeStep === 0 ? 
              <ShippingAddress
                setShippingAddressState={setShippingAddressState} 
                shippingAddressState={shippingAddressState} 
                setActiveStep={setActiveStep} />
              : null}
            {activeStep === 1 ? 
              <CheckoutForm
                setPaymentMethodState={setPaymentMethodState}
                paymentMethodState={paymentMethodState}
                shippingAddressState={shippingAddressState}
                setActiveStep={setActiveStep}
                setClientSecret={setClientSecret} />
              : null}
            {/* {activeStep === 2 ? 
              <ConfirmPayment
                setPaymentMethodState={setPaymentMethodState}
                shippingAddressState={shippingAddressState}
                clientSecret={clientSecret}
                setActiveStep={setActiveStep} />
              : null} */}
              {activeStep === 2 ? <PaymentComplete /> : null}
        </Box>
      {/* <Box className="c-footer">
        <Box className="f-box">
          <Text>© 2022 Sephora USA, Inc. All rights reserved.</Text>
          <Link to="#">
            <Text className="f-link">Terms of Use</Text>
          </Link>
          <Link to="#">
            <Text className="f-link">Privacy Policy</Text>
          </Link>
        </Box>
        <Box className="f-box">
          <Link to="#">
            <Text className="f-link">1-877-737-4672</Text>
          </Link>
          <Link to="#">
            <Text className="f-link">TTY: 1-888-866-9845</Text>
          </Link>
          <Link to="mailto:customerservice@sephora.com">
            <Text className="f-link">customerservices@sephora.com</Text>
          </Link>
        </Box>
      </Box> */}
    </Box>
    </Flex>
    </Stack>
    </div>
    </Elements>
  );
};

export default Checkout;
