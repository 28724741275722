import axios from "../token.interceptor";
import { API_URL } from "../config";
import CommonUtil from "../util/commonUtil";
export default class UserService {
  constructor() {
    this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";
    this.url = `${API_URL}/${this.lang}/api/v1/products`;
    this.token = CommonUtil.getAuthToken();
    
  }

  async getBanners() {
    const { data } = await axios.get(`${this.url}/banner/all`);
    return data;
  }

  async getBrands() {
    const { data } = await axios.get(`${this.url}/brands/groups`);
    return data;
  }

  async getProductTypes() {
    const { data } = await axios.get(`${this.url}/product/types`);
    return data;
  }

  async getChosenProducts() {
    const { data } = await axios.get(`${this.url}/filter/foryou`);
    return data;
  }

  async getRecommendedProducts() {
    const { data } = await axios.get(`${this.url}/recommended/products`, {headers: this.token})
    return data;
  }

  async getJustDropedProducts() {
    const { data } = await axios.get(`${this.url}/filter/justdroped`);
    return data;
  }

  async getBestSallerProducts() {
    const { data } = await axios.get(`${this.url}/filter/bestsaller`);
    return data;
  }

  async getOfferProducts() {
    const { data } = await axios.get(`${this.url}/filter/offer`);
    return data;
  }

  async getFeaturedCategories() {
    const { data } = await axios.get(`${this.url}/categories/featured`);
    return data;
  }

  async getListFilters() {
    const { data } = await axios.get(`${this.url}/filters`);
    return data;
  }

  async getShopProducts(filters,offset) {
    const { data } = await axios.post(`${this.url}/shop/?limit=64&offset=${offset}`,filters,{headers: this.token});
    return data;
  }

  async getAtoZBrands() {
    const { data } = await axios.get(`${this.url}/brands`);
    return data;
  }
  
  async getSingleProduct(slug) {
    const { data } = await axios.get(`${this.url}/product/${slug}/`,{headers: this.token});
    return data;
  }

  async likeUnlikeProduct(payload) {
    const { data } = await axios.post(`${this.url}/likes/`,payload,{headers: this.token});
    return data;
  }

  async getLikedProduct() {
    const { data } = await axios.get(`${this.url}/likes/`,{headers: this.token});
    return data;
  }
  async getLikedProductIds() {
    const { data } = await axios.get(`${this.url}/likes/ids/`,{headers: this.token});
    return data;
  }

  async addToCart(payload) {
    const { data } = await axios.post(`${this.url}/cart/`,payload,{headers: this.token});
    return data;
  }

  async getVariant(sku) {
    const { data } = await axios.get(`${this.url}/variant/${sku}/`,{headers: this.token});
    return data;
  }

  async getCart() {
    const { data } = await axios.get(`${this.url}/cart/`,{headers: this.token});
    return data;
  }

  async removeFromCart(payload) {
    const { data } = await axios.post(`${this.url}/cart/delete/`,payload,{headers: this.token});
    return data;
  }

  async searchProducts(query) {
    const { data } = await axios.get(`${this.url}/product/search/?q=${query}`,{headers: this.token});
    return data;
  }

  async getPurchasedProduct() {
    const { data } = await axios.get(`${this.url}/purchases/`,{headers: this.token});
    return data;
  }

  async getAllPernalizationsAttributes() {
    const { data } = await axios.get(`${this.url}/attributes/all/`,{headers: this.token});
    return data;
  }

  async createPersonalizationProfile(payload) {
    const { data } = await axios.post(`${this.url}/personalization/create/`,payload,{headers: this.token});
    return data;
  }

  async getTopBarDetails() {
    const { data } = await axios.get(`${this.url}/top/bar/`);
    return data;
  }

  
  async getTaxInfoPage(address_id) {
    const { data } = await axios.get(`${this.url}/cart/tax/${address_id}/`, {
      headers: this.token,
    });
    return data;
  }

  async applyCouponCode(couponCode, address_id) {
    const { data } = await axios.post(`${this.url}/cart/tax/${address_id}/`, {coupon_code: couponCode}, {
      headers: this.token,
    });
    console.log(data);
    return data;
  }

  async getallLanguages() {
    const { data } = await axios.get(`${this.url}/languages/`);
    return data;
  }

  
  async getallLanguageTranslation() {
    const { data } = await axios.get(`${this.url}/languages/translation/`);
    return data;
  }

  async createProductReview(payload) {
    const token = localStorage.getItem("token");
    var headers =  {
      Authorization: "Token " + token,
      'Content-Type': 'multipart/form-data',
    };
    const { data } = await axios.post(`${this.url}/post/review/`,payload,{headers: headers});
    return data;
  }

    
  async getAllSocialLinks() {
    const { data } = await axios.get(`${this.url}/get/social/links/`);
    return data;
  }


}