// import {
//     LIKE_PRODUCT,
//     LIKE_PRODUCT_LIST
// } from '../constants/productConstants';


export const userReducer = (state = { user: null}, action) => {
    switch (action.type) {
        case "LOAD_USER":
            const user = action.payload
            return { ...state, user: user }
        case "REMOVE_USER":
            return { ...state, user: {} }

        default:
            return state
    }
}

export const productLikeReducer = (state = { likedProductIds: []}, action) => {
    switch (action.type) {
        case "LIKE_PRODUCT":
            const product_id = action.payload
            const index = state.likedProductIds.indexOf(product_id)
            if (index > -1) {
                let deleted = state.likedProductIds.splice(index, 1)
                return {
                    ...state,
                    likedProductIds:  state.likedProductIds
                }

            } else {
                return {
                    ...state,
                    likedProductIds: [...state.likedProductIds, product_id]
                }
            }
        case "LIKE_PRODUCT_LIST":
            const product_ids = action.payload
            return {
                ...state,
                likedProductIds: product_ids
            }

        default:
            return state
    }
}


export const cart = (state = { cart: {items: []}}, action) => {
    switch (action.type) {
        case "UPDATE_CART":
            const cart = action.payload
                return {
                    ...state,
                    cart: cart
                }
        case "REMOVE_CART":
            return {
                ...state,
                cart: {items: []}
            }
        default:
            return state
    }
}

const topbar = {
    "top_bar_visible": false,
    "top_bar_text": "",
    "top_bar_color": "#ff0000",
    "top_bar_text_color": "#ffffff"
}
export const topBar = (state = { topbar: topbar}, action) => {
    switch (action.type) {
        case "UPDATE_TOPBAR":
            const payload = action.payload
                return {
                    ...state,
                    topbar: payload
                }

        default:
            return state
    }
}

const lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";
export const siteLanguage = (state = { lang: lang}, action) => {
    switch (action.type) {
        case "UPDATE_LANGUAGE":
            const payload = action.payload
            localStorage.setItem("lang",payload);
            return {
                ...state,
                lang: payload
            }

        default:
            return state
    }
}