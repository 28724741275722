import { Box, Button, Divider, Input, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useReducer } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";
import { BsFillCreditCard2FrontFill } from "react-icons/bs";

const reducer = (state, action) => {
  switch (action.type) {
    case 'CREATE_REQUEST':
      return { ...state, loading: true };
    case 'CREATE_SUCCESS':
      return { ...state, loading: false };
    case 'CREATE_FAIL':
      return { ...state, loading: false };
    default:
      return state;
  }
};
const Place = ({ address }) => {
  const navigate = useNavigate();

  const [{loading}, dispatch] = useReducer(reducer, {
      loading: false,
  });

  //price
  const roundPrice = (num) => Math.round(num * 100 + Number.EPSILON) / 100;
  // cart.itemsPrice = roundPrice(cart.cartItems.reduce((a, c) => a + c.quantity * c.price, 0));
  // cart.shippingPrice = cart.itemsPrice > 100 ? roundPrice(0) : roundPrice(10);
  // cart.taxPrice = roundPrice(0.16 * cart.itemsPrice); // VAT kenya
  // cart.totalPrice = cart.itemsPrice + cart.shippingPrice + cart.taxPrice;

  return (
    <Box className="place-main">
      <Box className="td1">
        <Text>Merchandise Subtotal</Text>
        <Text fontWeight={600}>$ </Text>
      </Box>
      <Box className="td1">
        <Link to="#">
          <Text className="td1-1">Shipping & Handling 🛈</Text>
        </Link>
        <Text fontWeight={600}>$  </Text>
      </Box>
      <Box className="td1">
        <Link to="#">
          <Text className="td1-1">Estimated Taxes 🛈</Text>
        </Link>
        <Text fontWeight={600}>$  0</Text>
      </Box>
      <hr style={{ border: "1px solid black" }} />
      <Box className="td2">
        <Text>Estimated Total</Text>
        <Text>$  </Text>
      </Box>
      <Box className="td3">
        <Text>
          or payments of $6.75 with <strong>Klarna.</strong>
        </Text>
        <Text mb="0.5rem">
          or <strong>afterpay 🛈</strong>
        </Text>
        <Divider />
      </Box>
      <Divider />
      <Box>
        <Text fontSize={"1rem"} fontWeight={700} textAlign="center" mt="0.5rem">
          The Sephora Credit Card Program
        </Text>
        <Box className="cc-p">
          <BsFillCreditCard2FrontFill size="3rem" />
          <Text>
            Save $6.75 on this order when you open and use either Sephora Credit
            Card today*
          </Text>
        </Box>
        <Text fontSize={"0.8rem"} color={"gray.500"}>
          *Subject to credit approval. Exclusions apply.
        </Text>
        <Button className="c-btn">SEE DETAILS</Button>
        <Divider />
        <Button
          colorScheme="teal"
          className="place-btn"
          
        >
          Place Order
        </Button>
      </Box>
    </Box>
  );
};

export default Place;
