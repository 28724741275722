export const MakeupObject = {
    value: "Makeup",
    links: [
        ["All Items", "/products"],
        ["Face", "/products/face"],
        ["Eye", "/products/eye"],
        ["Lip", "/products/lip"],
        ["Cheek", "/products/cheek"],
        
    ]
}