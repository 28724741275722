import React, { useEffect, useState,useReducer } from "react";
import { Checkbox, CheckboxGroup, Collapse, Radio, RadioGroup, Input, Button, Heading } from "@chakra-ui/react";
import { Box, Text, Stack, UnorderedList, ListItem } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '@chakra-ui/icons';

import ProductService from "../apis/product.api";

const Sidebar = ({setSelectedFilters, setProductReload, mainFilters, params, currentFilterSlug, setShowFilterSidebar}) => {
  const { t } = useTranslation();
  const [collapsed, setIsCollapsed] = useState({});
  const [filters, setFilters] = useState([]);
  const [reload, setReload] = useState(true);
  const [priceRange, setPriceRange] = useState({min:0,max:0});
  const [customPrioceSelected, setCustomPriceSelected] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [hiddenFilterState, setHiddenFilterState] = useState(true);
  const productApiService = new ProductService();
  

  const handleRadioChange = (value) => {
    setSelectedValue(value);
  };

  const getData = async () => {
    const res = await productApiService.getListFilters();
    setFilters(res)
    setReload(false);
    let filter_state = {};
    const filters = Object.keys(res);
    filters.map((filter) => {
      filter_state[filter] = false
    })
    setIsCollapsed(filter_state);

  }
  
  const handleFilterCollapse = (param) => {
    setIsCollapsed(prevState => ({
      ...prevState,
      [param]: !prevState[param]
    }))
  }

  const handleFilterChange = (e, type) => {

    let filterValue = e.target.value;
    setSelectedFilters(prevState => ({
      ...prevState,
      [type]: prevState[type].includes(filterValue) ? prevState[type].replace(`,${filterValue}`,"") : prevState[type]+","+filterValue
    }))
    setProductReload(true);
    setShowFilterSidebar(false);
  }

  const handleCustomFilterChange = (e, type) => {
    if(type==="age_range" || type==="price_range"){
      let filterValue = e.target.value
      if(type==="price_range" && filterValue === "custom"){
        filterValue = String(priceRange.min)+" to "+String(priceRange.max);
      }
      setSelectedFilters(prevState => ({
        ...prevState,
        [type]: filterValue
      }))
    }else{
      setSelectedFilters(prevState => ({
        ...prevState,
        [type]: !prevState[type]
      }))
    }
    setProductReload(true);
    setShowFilterSidebar(false);
  }

  useEffect(() => {
    
    if(reload){
      getData();
    }
  }, []);

  return (
    <div className="products-page-filters">
      <div className="sidebar-close-icon">
      <CloseIcon boxSize={5} color="gray.500" onClick={()=>setShowFilterSidebar((prev)=> !prev)}/>

      </div>

  <Heading className="product-list-filters-heading">{params.hasOwnProperty('subCatSlug') ? params.subCatSlug.replaceAll("-"," ") : params.slug?.replaceAll("-"," ")}</Heading>
    <UnorderedList className="filters-ul">
      {mainFilters.map((filter, index) => {
          return (
            currentFilterSlug !== "hooks" ?
            (<ListItem key={index} >
                <a href={`/products/category/${filter.slug}/${filter.slug2}`}>{filter.name} ({filter.count})</a>
            </ListItem>) : (
              <ListItem key={index} >
              <a href={`/products/hooks/${filter.slug}`}>{filter.name} ({filter.count})</a>
            </ListItem>
            )
          )
        })};
    </UnorderedList>
    
    {Object.keys(filters).map((filter, index) => {
      return (
        filter === "price_range" || filter === "age_range"? 
          <Box pr={10} key={index}>
          <Text fontSize="18px" fontWeight={"bold"}
            onClick={() => handleFilterCollapse(filter)}
            mb={4}
            className="product-list-filters"
          >
            {t(filter)} <p className="filter-icon">{collapsed[filter] ? <ChevronUpIcon /> : <ChevronDownIcon />}</p>
          </Text>
    
          <Collapse in={collapsed[filter]} animateOpacity style={{paddingTop: "16px"}}>
            <Box >
              <RadioGroup name="price_range" value={selectedValue} onChange={handleRadioChange}>
                <Stack spacing={5} direction='column'>
                  {filters[filter].map((item,idx) => {
                    return (
                      <Radio size='lg' onChange={(e)=>handleCustomFilterChange(e,filter)}  key={`${idx}-${index}`} value={item.name.replace("$","")} >{item.name}</Radio >
                    )
                  })}
                </Stack>
              
              { filter === "price_range" ?
              <>
              <Stack spacing={5} direction='row' className="price-range-radio">
             
                    <>
                      <Radio size='lg' className="price-range-input" value="custom" onClick={(e)=>setCustomPriceSelected(prevState => !prevState)}></Radio >
                      <Input type="number" placeholder='min' value={priceRange.min} onChange={(e)=>setPriceRange(prevState =>({...prevState, min:e.target.value}))} size='md' className="price-range-input" />
                      <Input type="number" placeholder='max' value={priceRange.max} onChange={(e)=>setPriceRange(prevState =>({...prevState, max:e.target.value}))} size='md' className="price-range-input" />
                    </>
               
             </Stack>
             <Button 
              colorScheme='teal'
              size='xs'
              onClick={(e)=>handleCustomFilterChange(e,filter)}
              className="price-range-button"
              value="custom"
              isDisabled={selectedValue !== "custom" || priceRange.min < 1 || priceRange.max < 1 || priceRange.min > priceRange.max ? true : false}
              >Apply</Button>
              </>: null}
              </RadioGroup>
            </Box>
          </Collapse>
          <hr style={{ margin: "5px 0" }} />
        </Box>
      :
      filter === "hooks" ? (
        null
      ): (
      <Box pr={10} key={index}>
        <Text fontSize="18px" fontWeight={"bold"}
          onClick={() => handleFilterCollapse(filter)}
          mb={4}
          className="product-list-filters"
        >
          {t(filter)} <p className="filter-icon">{collapsed[filter] ? <ChevronUpIcon /> : <ChevronDownIcon />}</p>
        </Text>
  
        <Collapse in={collapsed[filter]} animateOpacity style={{paddingTop: "16px"}}>
          <Box >
            <Stack spacing={5} direction='column'>
              {filters[filter].map((item,idx) => {
                return (
                  idx > 8 ?
                  <Checkbox size='lg' key={`${idx}-${index}`} className={`${hiddenFilterState ? "hidden-filter-ext" : ""}`} value={item.name} onChange={(e)=>handleFilterChange(e,filter)}>{item.name}</Checkbox>
                  :
                  <Checkbox size='lg' key={`${idx}-${index}`} value={item.name} onChange={(e)=>handleFilterChange(e,filter)}>{item.name}</Checkbox>
                  )
              })}
              {filters[filter].length > 8 ?
                <span className="see-more-filters" onClick={(e)=>setHiddenFilterState(prevState => !prevState)}>{!hiddenFilterState ? "Hide" :" See More"}</span>
              : null}
            </Stack>
          </Box>
        </Collapse>
        <hr style={{ margin: "5px 0" }} />
      </Box>
      )
        
      )
    })}
    {currentFilterSlug !== "hooks" ? (
      <Box className="custom-filters-main">
        <Stack spacing={5} direction='column'>
            {filters["hooks"]?.map((item,idx) => {
              return (
                <Checkbox size='lg' key={`${idx}-hook`} value={item.name} onChange={(e)=>handleFilterChange(e,"hooks")}>{item.name}</Checkbox>
                )
            })}
        </Stack>
      </Box>
    ): (null)}
      
      </div>
  )
}

export default Sidebar