import { createStore, combineReducers, applyMiddleware } from 'redux'    

import { productLikeReducer, cart, userReducer, topBar, siteLanguage } from "./reducers/productLike.reducer";



const rootReducer = combineReducers({
  productLike: productLikeReducer,
  cart: cart,
  user: userReducer,
  topBar: topBar,
  siteLanguage: siteLanguage
});

const store = createStore(rootReducer);

export default store;