import axios from "../token.interceptor";
import { API_URL } from "../config";
import CommonUtil from "../util/commonUtil";
export default class SubscriptionServices {
  constructor() {
    this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";
    this.url = `${API_URL}/${this.lang}/api/v1/subscriptons`;
    this.token = CommonUtil.getAuthToken();
    
  }

  async getAllSubscriptionPlans() {
    const { data } = await axios.get(`${this.url}/get-subscription-plans/`)
    return data;
  }

  async purchaseSubscriptionPlan(payload) {
    const { data } = await axios.post(`${this.url}/purchase-subscription-plan/`,payload,{headers: this.token});
    return data;
  }

  async getUserPurchasedPlan() {
    const { data } = await axios.get(`${this.url}/get-user-purchased-plan/`, { headers: this.token });
    return data;
  }

}