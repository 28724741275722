
import { GEOAPIFY_KEY, GEOAPIFY_URL } from "../config";
import axios from "axios";
export default class GeoapifyService {
  constructor() {
    this.apiKey = GEOAPIFY_KEY;
    this.base_url = GEOAPIFY_URL;
  }

  async getAddressDetails(text) {
    let url = `${this.base_url}?text=${text}&apiKey=${this.apiKey}&type=locality&country=United States of America`;
    const { data } = await axios.get(url);
    return data;
  }

}