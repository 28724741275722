import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from "react-redux";
import store from "./store/index";
// import { StoreProvider } from './store/index';
import './fonts/Helvetica-Neue-UltraLight.ttf';

import './index.css'
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="894926391742-dj6b3gce2l2kerag2jont57mqnoh5ro1.apps.googleusercontent.com">
    {/* <React.StrictMode> */}
      <ChakraProvider>
        <BrowserRouter>
        <Provider store={store}>
      
            <App />
          </Provider>
        
        </BrowserRouter>
      </ChakraProvider>
    {/* </React.StrictMode> */}
  </GoogleOAuthProvider>
);

// <Provider store={store}></Provider>If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
