import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Stack,
  StackDivider,
  Box,
  Text,
} from "@chakra-ui/react";

const OrderAddressCard = ({ shipping_address }) => {
  const { address, city, state, country, postalCode } = shipping_address;
  return (
    <Card marginTop={"20px"} minWidth={"250px"}>
      <CardBody>
        <Text pt="1" pb="2" fontSize="sm">
          <b>Shipping address</b>
        </Text>
        <Text pt="1" fontSize="sm">
          {address}
        </Text>
        <Text pt="1" fontSize="sm">
          {city}, {postalCode} {state}
        </Text>
        <Text pt="1" fontSize="sm">
          {country}
        </Text>
      </CardBody>
    </Card>
  );
};

export default OrderAddressCard;
