import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { 
    Box,
    Flex,
    Button,
    Heading,
    Text,
    List,
    ListItem,
    ListIcon,
    OrderedList,
    UnorderedList,
    Divider 
} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import AccountInfo from "./AccountInfo";
import ChangePassword from "./ChangePassword";
import "./MyAccounts.css"
const MyAccounts = () => {
    const { t } = useTranslation();
    const currentUser = useSelector((state) => state.user).user;
    const [activeStep, setActiveStep] = useState(1);
  return (
    <>
        <div id="my_account" style={{minHeight:"64vh"}}>
            <Box className="my-account-flex">
                <Box width={["100%","29%"]} display={"inline-block"}>
                    <List spacing={3} className='my-account-links'>
                        <ListItem cursor={"pointer"} onClick={(e)=> setActiveStep(1)} className={`sitebar-list-item ${activeStep == 1 ? "active": null}`}>
                            {/* <ListIcon  color='green.500' /> */}
                            {t("account_information")}
                        </ListItem>
                        <ListItem cursor={"pointer"} onClick={(e)=> setActiveStep(2)} className={`sitebar-list-item ${activeStep == 2 ? "active": null}`}>
                            {/* <ListIcon  color='green.500' /> */}
                            {t("change_password")}
                        </ListItem>
                    </List>
                </Box>
                <Box
                    display={"inline-block"}
                    borderLeft="2px solid #cccccc"  // Adjust the color and style as needed
                    height="auto"  // Adjust the height of the vertical line
                    margin="0 10px"  // Adjust the margin as needed
                    />
                <Box width={["100%","70%"]} display={"inline-block"}>
                    {activeStep == 1 ? <div className="my-account-section2">
                        <Heading as='h3' size='md' textAlign={"left"} marginBottom={"10px"}>{t("account_information")}</Heading>
                    <Divider height={"2px"} backgroundColor={"#000"} opacity={"1"} width={["100%","70%"]}/>
                    {currentUser? <AccountInfo currentUser={currentUser}/> : null}
                    </div> : null}
                    {activeStep == 2 ?<div className='my-account-section2'>
                        <Heading as='h3' size='md' textAlign={"left"} marginBottom={"10px"}>{t("change_password")}</Heading>
                    <Divider height={"2px"} backgroundColor={"#000"} opacity={"1"} width={["100%","70%"]}/>
                    {currentUser? <ChangePassword currentUser={currentUser}/> : null}
                    </div> : null}
                </Box>
            </Box>
            

        </div>

    </>
  )
}

export default MyAccounts