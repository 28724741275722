/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { size } from "lodash";
import { IoMdPaperPlane } from "react-icons/io";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js/pure";
import { STRIPE_PUBLICK_KEY } from "../../config";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import SubscriptionServices from "../../apis/subscription.api";
import CheckoutForm from "./CheckoutForm";
import "./subscription.css";


const Subscription = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [checkoutPage, setCheckoutPage] = useState(false);
  const [subscriptionPlans, setSubscirptionPlans] = useState([]);
  const [userExistingActivePlan, setUserExistingActivePlan] = useState({});
  const [paymentMethodState, setPaymentMethodState] = useState(undefined);

  const subscriptionServices = new SubscriptionServices();

  const getData = async () => {
    const res = await subscriptionServices.getAllSubscriptionPlans();
    setSubscirptionPlans(res || []);
  }

  const getUserPurchasedPlan = async () => {
    const res = await subscriptionServices.getUserPurchasedPlan();
    setUserExistingActivePlan(res);
  }

  const purchaseSubscriptionPlanHandler = async (id) => {
    setIsLoading(true);
    const res = await subscriptionServices.purchaseSubscriptionPlan({plan_id: selectedPlan.id,payment_method_id: paymentMethodState});
    getUserPurchasedPlan();
    
    toast.success(t("subscription_created"), {
      position: "top-right", // You can adjust the position
      autoClose: 3000, // Time in milliseconds for the toast to auto close
    });
    setCheckoutPage(false);
    setIsLoading(false);
  }

  useEffect(() => {
    getUserPurchasedPlan();
    getData();

  }, []);

  const stripePromise = loadStripe(STRIPE_PUBLICK_KEY);

  return (
    <Elements stripe={stripePromise}>
    { selectedPlan && checkoutPage ? <CheckoutForm 
      selectedPlan={selectedPlan} 
      setPaymentMethodState={setPaymentMethodState}
      paymentMethodState={paymentMethodState}
      purchaseSubscriptionPlanHandler={purchaseSubscriptionPlanHandler}
      isLoading={isLoading}
      setIsLoading={setIsLoading} /> :
    <>
      <section className="pricing-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="subscription-plan">
              <h2 className="text-center">Choose a Plan</h2>
                {/* <h3 className="text-center"></h3> */}
              </div>
            </div>
          </div>

          <div className="outer-box">
            <div className="row subscription-plan-list">
              {
                size(subscriptionPlans) ? subscriptionPlans.map((item, index) => {
                  return (
                    <div className="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp" key={`item-${index}`}>
                      <div className="inner-box">
                        <div className="icon-box">
                          <div className="icon-outer">
                            <IoMdPaperPlane size={70} />
                          </div>
                        </div>
                        <div className="price-box">
                          <div className="title"> {item?.name}</div>
                          <h4 className="price">${item?.price}</h4>
                          <h6 style={{fontSize: '14px'}}><b>per {item?.duration_type}</b></h6>
                        </div>
                        <ul className="features">
                          {
                            size(item?.features) ? 
                            item?.features.map((item, index) => {
                              return (
                                  <li className="true" key={`item-${index}`}>{item?.name}</li>
                              );
                            })
                            : ""
                          }
                        </ul>
                        <div className="btn-box">
                          <button
                            className="theme-btn "
                            type="button"
                            onClick={()=> {
                              // purchaseSubscriptionPlanHandler(item?.id)
                              setSelectedPlan(item);
                              setCheckoutPage(true);
                            }}
                            disabled={(item?.id === selectedPlan && isLoading) || userExistingActivePlan?.plan?.id === item?.id}
                          >
                            {
                              (item?.id === selectedPlan && isLoading) || !userExistingActivePlan?.plan?.id === item?.id ? 'Request processing' : (item?.id === selectedPlan && isLoading) || userExistingActivePlan?.plan?.id === item?.id ? 'Current Plan' : 'BUY NOW'
                            }
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                }) : ""
              }
            </div>
          </div>
        </div>
      </section>
    </>}
    </Elements>
  );
};

export default Subscription;
