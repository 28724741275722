import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  FormErrorMessage
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import UserService from '../../apis/user.api';

export default function Signup() {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    getValues
  } = useForm();

  const Navigate = useNavigate();
  const userService = new UserService();

  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (data) => {
    try {
      const userData = new FormData();
      userData.append("first_name",data.first_name)
      userData.append("last_name",data.last_name)
      userData.append("email",data.email)
      userData.append("phone_number",data.phone_number)
      userData.append("password",data.password)
      const res = await userService.signup(userData);
      localStorage.setItem("userInfo", JSON.stringify(res));
      toast.success(t("successfully_registered"), {
        position: "top-right", // You can adjust the position
        autoClose: 3000, // Time in milliseconds for the toast to auto close
      });
      Navigate('/login');
  } catch(error) {
      console.log("Error!");
      toast.error(t("registration_failed"), {
        position: "top-right", // You can adjust the position
        autoClose: 3000, // Time in milliseconds for the toast to auto close
      });;
  }
}

  return (
    <div>
      <Flex minH={"100vh"} align={"center"} justify={"center"} bg={"white"}>

        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"} textAlign={"center"}>
              {t("signup")}
            </Heading>
          </Stack>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={4}>
                
                <HStack>
                  <Box>
                    <FormControl id="firstName" isInvalid={errors.first_name} isRequired>
                      <FormLabel htmlFor="first_name">{t("first_name")}</FormLabel>
                      <Input
                        id="first_name"
                        placeholder={t("first_name")}
                        {...register('first_name', {
                          required: 'This is required',
                          minLength: { value: 4, message: 'Minimum length should be 4' },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.first_name && errors.first_name.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl id="last_name" isInvalid={errors.last_name} isRequired>
                      <FormLabel htmlFor="last_name">{t("last_name")}</FormLabel>
                        <Input
                        id="last_name"
                        placeholder={t("last_name")}
                        {...register('last_name', {
                          required: 'This is required',
                          minLength: { value: 4, message: 'Minimum length should be 4' },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.last_name && errors.last_name.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                </HStack>
                <FormControl id="phone_number" isInvalid={errors.phone_number} isRequired>
                      <FormLabel htmlFor="phone_number">{t("phone_number")}</FormLabel>
                      <Input
                        id="phone_number"
                        placeholder={t("phone_number")}
                        {...register('phone_number', {
                          required: 'This is required',
                          validate: {
                            matchPattern: (v) =>
                              /^\+[1-9]\d{1,3}\d{1,10}$/.test(v) ||
                              "Phone number should have the country code.",
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.phone_number && errors.phone_number.message}
                      </FormErrorMessage>
                    </FormControl>
                <FormControl id="email" isInvalid={errors.email} isRequired>
                  <FormLabel htmlFor="email">{t("email_address")}</FormLabel>
                  <Input
                        id="email"
                        placeholder={t("email_address")}
                        {...register('email', {
                          required: 'This is required',
                          validate: {
                            maxLength: (v) =>
                              v.length <= 50 || "The email should have at most 50 characters",
                            matchPattern: (v) =>
                              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                              "Email address must be a valid address",
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.email && errors.email.message}
                      </FormErrorMessage>
                </FormControl>
                <FormControl id="password" isInvalid={errors.password} isRequired>
                  <FormLabel htmlFor="password">{t("password")}</FormLabel>
                  <InputGroup className="signup-password" style={{display: "block"}}>
                    <Input
                        id="password"
                        placeholder={t("password")}
                        autoComplete="off"
                        type={!showPassword?"password":"text"}
                        {...register('password', {
                          required: 'This is required',
                          validate: {
                            matchPattern: (v) =>
                              /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[A-Z])(?=.*[0-9]).{6,}$/.test(v) ||
                              "Password should have 1).Minimum length of 6 characters, 2).At least 1 special character,\
                              3).At least 1 uppercase letter, 4).At least 1 numeric digit",
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.password && errors.password.message}
                      </FormErrorMessage>
                    <InputRightElement h={"full"} style={errors.password ? {top:"-33px"} : null}> 
                      <Button
                        variant={"ghost"}
                        onClick={() =>
                          setShowPassword((showPassword) => !showPassword)
                        }
                      >
                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl id="confirm_password" isInvalid={errors.confirm_password} isRequired>
                  <FormLabel htmlFor="confirm_password">{t("confirm_password")}</FormLabel>
                  <Input
                        id="confirm_password"
                        placeholder={t("confirm_password")}
                        type={!showPassword?"password":"text"}
                        autoComplete="off"
                        style={{display: "block"}}
                        {...register('confirm_password', {
                          required: 'This is required',
                          validate: {
                            match: (password) =>
                              password === getValues().password ||
                              "Confirm password should be same as password",
                          }, 
                        })}
                      />
                      <FormErrorMessage>
                        {errors.confirm_password && errors.confirm_password.message}
                      </FormErrorMessage>
                </FormControl>
                <Stack spacing={10} pt={2}>
                  <Button
                    type="submit"
                    loadingText="Submitting"
                    size="lg"
                    bg={"black"}
                    color={"white"}
                    _hover={{
                      bg: "black",
                    }}
                  >
                    {t("signup")}?
                  </Button>
                </Stack>
                <Stack pt={6}>
                  <Text align={"center"}>
                  {t("already_have_an_account")}?
                    <Link to="/login">
                      <Text as="u">{t("login")}</Text>
                    </Link>
                  </Text>
                </Stack>
              </Stack>
            </form>
          </Box>

          <ToastContainer theme="colored" />
        </Stack>
      </Flex>
    </div>
  );
}
