import { useGoogleLogin  } from '@react-oauth/google';
import { Text, Button, Center } from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
import { useTranslation } from 'react-i18next';

const GoogleLoginCopm = (props) => {
    const { t } = useTranslation();
    const login = useGoogleLogin({
        onSuccess: tokenResponse => props.handleGoogleLogin(tokenResponse),
      });
      
  return (
        <Button
            w={"full"}
            maxW={"md"}
            variant={"outline"}
            leftIcon={<FcGoogle />}
            onClick={() => login()}
        >
            <Center>
                <Text>{t("sign_in_with_google")}</Text>
            </Center>
        </Button>
  );
};

export default GoogleLoginCopm;