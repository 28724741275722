import { Heading, Text, Link } from "@chakra-ui/react";
import React, { useContext, useEffect, useState} from 'react';
import ProductService from "../../../apis/product.api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./FeaturedCategories.css";

const FeaturedCategories = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [reload, setReload] = useState(true);
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const navigate = useNavigate();
  const chevronWidth = 8;

  const productApiService = new ProductService();


  const getData = async () => {
    const res = await productApiService.getFeaturedCategories();
    setCategories(res)
    setReload(false);
  }
  useEffect(() => {
    if(reload){
      getData();
    }
  }, []);

  return (
    <>
      <div className="featured_container">
        <div className="featured_box1">
          <Heading as="h4" size="md">
            {" "}
            {t("featured_categories")}{" "}
          </Heading>
          <Text fontSize="md" ml="0px" mt="5px" fontWeight="500">
            Shop what's popular now.
          </Text>
        </div>
        
         <div className="featured_box2" >
         {categories.map((item, index) => {
          return (
            
              <div className="featured_sub_box" key={index}>
            <Link onClick={() => navigate(`/products/category/${item.slug}`)}>
            <Text fontSize="sm" ml="0px" mt="5px" fontWeight="50">
                {item.name}
              </Text>
    
                
                <div className="categories-avatar-image">
                  <img
                  src={item.avatar}
                  alt={item.index}
                /></div>
                 </Link>
            </div> 
           
         )})}
      
        </div>  
      </div> 
      <hr style={{ margin: "50px 0" }} />
    </>
  );
};

export default FeaturedCategories;
