
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { Select, Box, Flex, Text } from '@chakra-ui/react';
import Flags, { BO } from 'country-flag-icons/react/3x2'

import ProductService from "../../apis/product.api";

const LanguageSwitcher = () => {
  const lang = useSelector((state) => state.siteLanguage).lang;

  const productApiService = new ProductService();
  const currentLanguageCode = useSelector((state) => state.siteLanguage).lang;

  const dispatch = useDispatch();
  const [languages, setLanguages] = useState([]);
  const [showLanguageSelect, setShowLanguageSelect] = useState(false);

  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    dispatch({type: "UPDATE_LANGUAGE",payload: lng});
    setShowLanguageSelect(false);
  };


  const CurrentLanguage = () => {
    const language = languages.find(item => item.language_code === currentLanguageCode);
    return (
      <Box onClick={(e)=>setShowLanguageSelect((prev) => !prev)}>
        {language ? 
        <Flex align={"center"}>
          <CountryFlag code={language.country_code} />
          <Text marginLeft={"5px"}>{language.language_code}-{language.country_code} <span className='country-name'>{language.language}</span></Text>
        </Flex> : null }
      </Box>
    )
  }
  const fetchData = async () => {
    const result = await productApiService.getallLanguages();
    setLanguages(result);
  }
  useEffect(() => {
    fetchData();
  },[]);

  const CountryFlag = ({code}) => {
    const Flag = Flags[code];
    return <Flag height={"15px"}/>;
  }
  return (
    <Box className="select-language-select">
      <CurrentLanguage />
      <Box className={`custom-language-select ${!showLanguageSelect ? "hidden" : ""}`} height={"50px"}>
          {languages.map((language, idx) => {
              return (
                currentLanguageCode !== language.language_code ? <Box key={idx} className="custom-language-select-option" onClick={(e)=> changeLanguage(language.language_code)}>
                  <Flex align={"center"}>
                    <CountryFlag code={language.country_code} />
                    <Text marginLeft={"5px"}>{language.language_code}-{language.country_code} <span className='country-name'>{language.language}</span></Text>
                  </Flex>
                </Box> : null
              )
          })}
      </Box>
        {/* <Select size={"sm"} placeholder='Select language'  onChange={(e)=> changeLanguage(e)}>
           
        </Select> */}
    </Box>
  );
};

export default LanguageSwitcher;