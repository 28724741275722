import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  HStack,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  FormErrorMessage,

} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import React, { useState  } from "react";
import { useTranslation } from 'react-i18next';
import "./ContactUs.css";
import UserService from "../../apis/user.api";
const loading_icon = require("./loading-icon.gif");

const MyWishlist = () => {
  const { t } = useTranslation();
  const userApiService = new UserService();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    getValues
  } = useForm();

  const Navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const userData = new FormData();
      userData.append("first_name",data.first_name)
      userData.append("last_name",data.last_name)
      userData.append("email",data.email)
      userData.append("phone_number",data.phone_number)
      userData.append("message",data.message)
      const res = await userApiService.contactUsEmail(userData);
      toast.success(t("contact_page_thanks_message"), {
        position: "top-right", // You can adjust the position
        autoClose: 3000, // Time in milliseconds for the toast to auto close
      });
      Navigate('/');
  } catch(error) {
      toast.error(t("internal_server_error"), {
        position: "top-right", // You can adjust the position
        autoClose: 3000, // Time in milliseconds for the toast to auto close
      });
  }
  setIsLoading(false);
}


  return (
    <>
         <div>
      <Flex flexDir="column" width="100%">

        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"} textAlign={"center"}>
            {t("contact_us")}
            </Heading>
          </Stack>
          
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={4}>
                <>
                <HStack>
                  <Box>
                    <FormControl id="firstName" isInvalid={errors.first_name} isRequired>
                      <FormLabel htmlFor="first_name">{t("first_name")}</FormLabel>
                      <Input
                        id="first_name"
                        placeholder={t("first_name")}
                        {...register('first_name', {
                          required: 'This is required',
                          minLength: { value: 4, message: 'Minimum length should be 4' },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.first_name && errors.first_name.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl id="last_name" isInvalid={errors.last_name} isRequired>
                      <FormLabel htmlFor="last_name">{t("last_name")}</FormLabel>
                        <Input
                        id="last_name"
                        placeholder={t("last_name")}
                        {...register('last_name', {
                          required: 'This is required',
                          minLength: { value: 4, message: 'Minimum length should be 4' },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.last_name && errors.last_name.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                </HStack>
                <FormControl id="phone_number" isInvalid={errors.phone_number} isRequired>
                      <FormLabel htmlFor="phone_number">{t("phone_number")}</FormLabel>
                      <Input
                        id="phone_number"
                        placeholder={t("phone_number")}
                        {...register('phone_number', {
                          required: 'This is required',
                          validate: {
                            matchPattern: (v) =>
                              /^\+[1-9]\d{1,3}\d{1,10}$/.test(v) ||
                              "Phone number should have the country code.",
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.phone_number && errors.phone_number.message}
                      </FormErrorMessage>
                    </FormControl>
                <FormControl id="email" isInvalid={errors.email} isRequired>
                  <FormLabel htmlFor="email">{t("email_address")}</FormLabel>
                  <Input
                        id="email"
                        placeholder={t("email_address")}
                        {...register('email', {
                          required: 'This is required',
                          validate: {
                            maxLength: (v) =>
                              v.length <= 50 || "The email should have at most 50 characters",
                            matchPattern: (v) =>
                              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                              "Email address must be a valid address",
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.email && errors.email.message}
                      </FormErrorMessage>
                </FormControl>
                <FormControl id="message" isInvalid={errors.message} isRequired>
                  <FormLabel htmlFor="message">{t("message")}</FormLabel>
                  <Textarea
                        id="message"
                        placeholder={t("message")}
                        {...register('message', {
                          required: 'This is required',
                        })}
                      />
                      <FormErrorMessage>
                        {errors.message && errors.message.message}
                      </FormErrorMessage>
                </FormControl>
              
                </>
                
              
                <Stack spacing={10} pt={2}>
                  {isLoading ? (
                    <Button
                      type="button"
                      loadingText="Submitting"
                      size="lg"
                      bg={"black"}
                      color={"white"}
                      _hover={{
                        bg: "black",
                      }}
                      // isDisabled={true}
                    >
                    <img src={loading_icon} style={{width: "80px"}}/>
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      loadingText="Submitting"
                      size="lg"
                      bg={"black"}
                      color={"white"}
                      _hover={{
                        bg: "black",
                      }}
                    >
                      {t("contact_us")}
                  </Button>
                  ) }
                 
                </Stack>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Flex>
    </div>
    </>
  );
};

export default MyWishlist;
