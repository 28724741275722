import { Box, Button, Card, CardBody, Flex, Text } from "@chakra-ui/react";

const OrderActions = ({ first_name, last_name, email, mobile }) => {
  return (
    <Card marginTop={"20px"} variant="unstyled">
      <CardBody>
        <Text pt="1" fontSize="sm">
          <b>
            {first_name} {last_name}
          </b>
        </Text>
        <Text pt="1" fontSize="sm">
          {email}
        </Text>
        <Text pt="1" fontSize="sm">
          {mobile}
        </Text>
        <Box marginTop="10px">
          <Button colorScheme="red">Cancel order</Button>
        </Box>
      </CardBody>
    </Card>
  );
};

export default OrderActions;
