import { Box, Container, Flex } from "@chakra-ui/react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from "@chakra-ui/react";
import OrderAddressCard from "./OrderAddressCard";

import OrderStatusTracker from "./OrderStatusTracker";
import OrderActions from "./OrderActions";
import OrderProductList from "./OrderProductList";
import OrderService from "../../apis/order.api";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const OrderDetailsPage = () => {
  const ApiOrdersService = new OrderService();
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState({});

  const fetchData = async () => {
    const results = await ApiOrdersService.getOrderDetails(id);
    setOrderDetails(results);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box width="68%" margin="40px auto">
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink href="/myorders">Orders</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#">
            <b>Order #{orderDetails.id}</b>
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      {orderDetails.status ?
      <OrderStatusTracker {...orderDetails} /> : null }


      <Flex justifyContent={"space-between"} wrap="wrap">
        <OrderAddressCard
          shipping_address={orderDetails.order_shipping_address || {}}
        />
        <OrderActions
          first_name={orderDetails.order_shipping_address?.first_name}
          last_name={orderDetails.order_shipping_address?.last_name}
          email={orderDetails.order_shipping_address?.email}
          mobile={orderDetails.order_shipping_address?.mobile}
        />
      </Flex>

      {orderDetails.order_items ? <OrderProductList {...orderDetails} /> : null}
    </Box>
  );
};

export default OrderDetailsPage;
