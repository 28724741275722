import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import React, {useState, useEffect} from "react";
import {useNavigate} from 'react-router-dom';

import { 
    Box,  
    Input,
    Button,
    FormControl,
    FormLabel,
    VStack,
    Text,
    Flex,
    RadioGroup,
    Radio,
    Stack } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import OrderService from "../../apis/order.api";
import ProductService from "../../apis/product.api";


import visaImage from './images/visa-payment-card.jpg';
import mastercardImage from './images/master-card.png';
import amexImage from './images/amex-card-payment.png';
import discoverImage from './images/discover-card.png';
import jcbImage from './images/jcb-payment-cards.png';
import defaultImage from './images/simple-card-credit.png';
import loaderImage from './images/loader.gif';

const CheckoutForm = ({ selectedPlan, setPaymentMethodState, paymentMethodState, purchaseSubscriptionPlanHandler, isLoading, setIsLoading }) => {

  const { t } = useTranslation();

  const currentUser = useSelector((state) => state.user).user;
  const orderApiService = new OrderService();
  const productApiService = new ProductService();

  const [clientSecret, setClientSecret] = useState('');
  const [error, setError] = useState(null);
  const [allPaymentMethods, setAllPaymentMethods] = useState([]);
  const [email, setEmail] = useState(currentUser.email);
  const [newCardState, setNewCardState] = useState(false);
  const [cart, setCart] = useState(undefined);
  const [reload, setReload] = useState(true);

  const Navigate = useNavigate()
  const stripe = useStripe();
  const elements = useElements();


// Handle real-time validation errors from the CardElement.
const handleChange = (event) => {
  if (event.error) {
    setError(event.error.message);
  } else {
    setError(null);
  }
}


const fetchData = async () => {
    setIsLoading(true);
    // setReload(false);
    // const new_cart = await productApiService.getTaxInfoPage(shippingAddressState);
    // setCart(new_cart);
    const result = await orderApiService.getAllPaymentMethods();
    setAllPaymentMethods(result);
    setIsLoading(false);
 }

const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const card = elements.getElement(CardElement);
   // add these lines
    const {paymentMethod, error} = await stripe.createPaymentMethod({
       type: 'card',
       card: card
  });
  if(paymentMethod){
    const payload = {
        "payment_method_id":paymentMethod.id,
        // "shipping_address": shippingAddressState
    }
     const result = await orderApiService.createPaymentMethod(payload);
     fetchData();
  }
  setNewCardState(false);
  setIsLoading(false);
  }

  const handleMakePayment = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const payload = {
        "payment_method_id":paymentMethodState,
        // "shipping_address_id":shippingAddressState
    }
    const result = await orderApiService.createPaymentIntent(payload);
    setClientSecret(result.client_secret);
    // setActiveStep(2);
    setIsLoading(false);
    Navigate("/myorders");
  }

 useEffect(() => {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
  if(reload){
    fetchData();
  }

}, [reload]);

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: 'inherit',
        '::placeholder': {
          color: '#718096',
        },
        '::selection':{
            border: '1px solid red'
        },
        border: '1px solid #2c7a7b',
        lineHeight: "35px",
        borderSize: "1px"
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
  const [savedCards, setSavedCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const handleCardSelect = (card) => {
    setSelectedCard(card);
  };

  const handlePayment = async () => {
    if (!stripe || !elements || !selectedCard) {
      return;
    }
    }
    const cardBrandImages = {
        visa: visaImage,
        mastercard: mastercardImage,
        amex: amexImage,
        discover: discoverImage,
        jcb: jcbImage,
        default: defaultImage,
      };

    const renderCardImage = (cardBrand) => {
    const imageUrl = cardBrandImages[cardBrand] || cardBrandImages.default;
    return <img src={imageUrl} alt={cardBrand} className="card-image"/>;
    };
return (
  <div
  className="subscription_main"
  style={{minHeight:"64vh"}}
>
  <Stack width={'70%'} margin={'auto'}>
    <Box position={"relative"}>
      {isLoading ? (
        <Box className="checkout-loader">
          <img src={loaderImage} />
        </Box>
      ): null}
      
        <Flex justify="space-between" width={"100%"}>
            <Box marginTop={"20px"} width={"50%"} className="payment-method-form-main">
              <Flex justifyContent="space-between">
                <Text marginLeft="0px" fontWeight="bold">{t("plan_name")}</Text>
                <Text marginLeft="0px">{selectedPlan?.name}</Text>
              </Flex>

              <Flex justifyContent="space-between">
                <Text marginLeft="0px" fontWeight="bold">{t('price')}</Text>
                <Text marginLeft="0px">${selectedPlan?.price}</Text>
              </Flex>

              <Flex justifyContent="space-between">
                <Text marginLeft="0px" fontWeight="bold">{t("plan_duration")}</Text>
                <Text marginLeft="0px">{selectedPlan?.duration_type}</Text>
              </Flex>

              <hr style={{margin: "5px 0px"}}/>
              <Flex justifyContent="space-between">
                <Text marginLeft="0px" fontWeight="bold">{t("total")}</Text>
                <Text marginLeft="0px">${selectedPlan?.price}</Text>
              </Flex>
            </Box>
           <Box width={"50%"} marginTop={"20px"} className="payment-method-list-main">
            {!newCardState ? (
           <VStack spacing={4} align="center">
          
                <Text fontWeight="bold">{t("select_a_saved_card")}:</Text>
            <RadioGroup onChange={setPaymentMethodState} value={paymentMethodState} width={"100%"}>
                {allPaymentMethods?.map((card,idx) =>{
                    return (
                                
                         <Box key={idx} textAlign={"left"} className="shipping-address-list-div">
                            <Radio value={`${card.id}`}>
                            
                            <Text>{renderCardImage(card.card.brand)} **** **** **** {card.card.last4} <span>{card.card.brand}</span></Text>
                          
                            </Radio>

                        </Box> 
                      
                    )
                })}
            </RadioGroup>
            <Box style={{width: "100%"}}>
              <button style={{float: "left", color: "rgb(25 63 147)"}} onClick={()=> setNewCardState(true)}>+ {t("add_new_card")}</button>

            </Box>
            <Button onClick={(e) => purchaseSubscriptionPlanHandler()} variant="outline" colorScheme='teal' isDisabled = {paymentMethodState ? false : true}>{t("make_payment")}</Button>
            </VStack>
            ) : (
              <>
               <Box style={{width: "100%"}}>
                <button style={{float: "left", color: "rgb(25 63 147)"}} onClick={()=> setNewCardState(false)}>+ {t("use_saved_card")}</button>

              </Box>
            <form onSubmit={handleSubmit} className="stripe-form">
                    <VStack spacing={4} style={{width: "100%"}}>
                        <Box className="checkout-card-form-main">
                        <FormControl>
                            <FormLabel htmlFor="email">{t("email_address")}</FormLabel>
                            <Input placeholder="Email" isReadOnly={true} required value={email} onChange={(event) => { setEmail(event.target.value)}} />
                        </FormControl>
                        <Box className="form-row" width={"100%"} >
                            <FormLabel htmlFor="card-element">Credit or debit card</FormLabel> 
                            <CardElement id="card-element" className="stripe-card-input-box" options={cardElementOptions}  onChange={handleChange}/>
                            <div className="card-errors" role="alert">{error}</div>
                        </Box>
                        <Button variant="outline" color={"#2c7a7b"} borderColor={"#2c7a7b"} type="submit">{t("add_new_card")} </Button>
                        </Box>
                    </VStack>
                </form>
              </>
          
            )}

           </Box>
        </Flex>
    </Box>
    </Stack>
    </div>
 );
};
export default CheckoutForm;