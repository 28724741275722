import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import "react-toastify/dist/ReactToastify.css";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  FormErrorMessage,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import UserService from '../../apis/user.api';

export default function Signup() {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    getValues
  } = useForm();

  const Navigate = useNavigate();
  const userService = new UserService();

  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (data) => {
    try {
      const userData = new FormData();
      userData.append("first_name",data.first_name)
      userData.append("last_name",data.last_name)
      userData.append("email",data.email)
      userData.append("phone_number",data.phone_number)
      userData.append("website",data.website)
      userData.append("company_name",data.company_name)
      userData.append("company_email",data.company_email)
      userData.append("address",data.address)
      userData.append("city",data.city)
      userData.append("state",data.state)
      userData.append("country",data.country)
      userData.append("postal_code",data.phone_number)
      userData.append("password",data.password)
      const res = await userService.professionalSignup(userData);
      console.log(res);
      toast.success(t("account_submitted"), {
        position: "top-right", // You can adjust the position
        autoClose: 3000, // Time in milliseconds for the toast to auto close
      });
      Navigate('/login');
  } catch(error) {
      toast.error(t("registration_failed"), {
        position: "top-right", // You can adjust the position
        autoClose: 3000, // Time in milliseconds for the toast to auto close
      });
  }
}


const steps = [
  { title: t("company_info"), description: "" },
  { title: t("account_info"), description: "" },
];
const statusIndexMap = {
  created: 0,
  OnHold: 1
};

const { activeStep, setActiveStep } = useSteps({
  index: statusIndexMap[0],
  count: steps.length,
});
const max = 1;

  return (
    <div>
      <Flex flexDir="column" width="100%">

        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"} textAlign={"center"}>
              {t("signup")}
            </Heading>
          </Stack>
          <Stepper size="sm" index={activeStep} marginTop={"20px"} width={"100%"}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box>
                <StepTitle>{step.title}</StepTitle>
                <StepDescription margin={"0"}>{step.description}</StepDescription>
              </Box>

              <StepSeparator />
            </Step>
          ))}
        </Stepper> 
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={4}>
                
              {activeStep === 0 ?(
                <>
                <HStack>
                  <Box>
                    <FormControl id="companyName" isInvalid={errors.company_name} isRequired>
                      <FormLabel htmlFor="company_name">{t("company_name")}</FormLabel>
                      <Input
                        id="company_name"
                        placeholder={t("company_name")}
                        {...register('company_name', {
                          required: 'This is required',
                          minLength: { value: 4, message: 'Minimum length should be 4' },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.company_name && errors.company_name.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl id="website" isInvalid={errors.website} isRequired>
                      <FormLabel htmlFor="website">{t("website")}</FormLabel>
                      <Input
                        id="website"
                        placeholder={t("website")}
                        {...register('website', {
                          required: 'This is required',
                          minLength: { value: 4, message: 'Minimum length should be 4' },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.website && errors.website.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                </HStack>
                <FormControl id="company_email" isInvalid={errors.company_email} isRequired>
                  <FormLabel htmlFor="company_email">{t("company_email")}</FormLabel>
                  <Input
                        id="company_email"
                        placeholder={t("company_email")}
                        {...register('company_email', {
                          required: 'This is required',
                          validate: {
                            maxLength: (v) =>
                              v.length <= 50 || "The email should have at most 50 characters",
                            matchPattern: (v) =>
                              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                              "Email address must be a valid address",
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.company_email && errors.company_email.message}
                      </FormErrorMessage>
                </FormControl>
                <FormControl id="address" isInvalid={errors.address} isRequired>
                  <FormLabel htmlFor="address">{t("company_address")}</FormLabel>
                  <Textarea
                    id="address"
                    placeholder={t("company_address")}
                    {...register('address', {
                      required: 'This is required',
                      minLength: { value: 4, message: 'Minimum length should be 4' },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.address && errors.address.message}
                  </FormErrorMessage>
                </FormControl>
                <HStack>
                  <Box>
                    <FormControl id="city" isInvalid={errors.city} isRequired>
                      <FormLabel htmlFor="city">{t("city")}</FormLabel>
                      <Input
                        id="city"
                        placeholder={t("city")}
                        {...register('city', {
                          required: 'This is required',
                          minLength: { value: 4, message: 'Minimum length should be 4' },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.city && errors.city.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl id="state" isInvalid={errors.state} isRequired>
                        <FormLabel htmlFor="state">{t("state")}</FormLabel>
                        <Input
                          id="state"
                          placeholder={t("state")}
                          {...register('state', {
                            required: 'This is required',
                            minLength: { value: 4, message: 'Minimum length should be 4' },
                          })}
                        />
                        <FormErrorMessage>
                          {errors.state && errors.state.message}
                        </FormErrorMessage>
                      </FormControl>
                  </Box>
                </HStack>
                <HStack>
                  <Box>
                    <FormControl id="country" isInvalid={errors.country} isRequired>
                      <FormLabel htmlFor="country">{t("country")}</FormLabel>
                      <Input
                        id="country"
                        placeholder={t("country")}
                        {...register('country', {
                          required: 'This is required',
                          minLength: { value: 4, message: 'Minimum length should be 4' },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.country && errors.country.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl id="postal_code" isInvalid={errors.postal_code} isRequired>
                        <FormLabel htmlFor="postal_code">{t("postal_code")}</FormLabel>
                        <Input
                          id="postal_code"
                          placeholder={t("postal_code")}
                          {...register('postal_code', {
                            required: 'This is required',
                            minLength: { value: 4, message: 'Minimum length should be 4' },
                          })}
                        />
                        <FormErrorMessage>
                          {errors.postal_code && errors.postal_code.message}
                        </FormErrorMessage>
                      </FormControl>
                  </Box>
                </HStack>
                </> ) : null }
    

                {activeStep === 1 ?(
                <>
                <HStack>
                  <Box>
                    <FormControl id="firstName" isInvalid={errors.first_name} isRequired>
                      <FormLabel htmlFor="first_name">{t("first_name")}</FormLabel>
                      <Input
                        id="first_name"
                        placeholder={t("first_name")}
                        {...register('first_name', {
                          required: 'This is required',
                          minLength: { value: 4, message: 'Minimum length should be 4' },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.first_name && errors.first_name.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl id="last_name" isInvalid={errors.last_name} isRequired>
                      <FormLabel htmlFor="last_name">{t("last_name")} </FormLabel>
                        <Input
                        id="last_name"
                        placeholder={t("last_name")}
                        {...register('last_name', {
                          required: 'This is required',
                          minLength: { value: 4, message: 'Minimum length should be 4' },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.last_name && errors.last_name.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                </HStack>
                <FormControl id="phone_number" isInvalid={errors.phone_number} isRequired>
                      <FormLabel htmlFor="phone_number">{t("phone_number")} </FormLabel>
                      <Input
                        id="phone_number"
                        placeholder={t("phone_number")} 
                        {...register('phone_number', {
                          required: 'This is required',
                          validate: {
                            matchPattern: (v) =>
                              /^\+[1-9]\d{1,3}\d{1,10}$/.test(v) ||
                              "Phone number should have the country code.",
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.phone_number && errors.phone_number.message}
                      </FormErrorMessage>
                    </FormControl>
                <FormControl id="email" isInvalid={errors.email} isRequired>
                  <FormLabel htmlFor="email">{t("email_address")}</FormLabel>
                  <Input
                        id="email"
                        placeholder={t("email_address")}
                        {...register('email', {
                          required: 'This is required',
                          validate: {
                            maxLength: (v) =>
                              v.length <= 50 || "The email should have at most 50 characters",
                            matchPattern: (v) =>
                              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                              "Email address must be a valid address",
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.email && errors.email.message}
                      </FormErrorMessage>
                </FormControl>
                <FormControl id="password" isInvalid={errors.password} isRequired>
                  <FormLabel htmlFor="password">{t("password")}</FormLabel>
                  <InputGroup className="signup-password" style={{display: "block"}}>
                    <Input
                        id="password"
                        placeholder={t("password")}
                        autoComplete="off"
                        type={!showPassword?"password":"text"}
                        {...register('password', {
                          required: 'This is required',
                          validate: {
                            matchPattern: (v) =>
                              /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[A-Z])(?=.*[0-9]).{6,}$/.test(v) ||
                              "Password should have 1).Minimum length of 6 characters, 2).At least 1 special character,\
                              3).At least 1 uppercase letter, 4).At least 1 numeric digit",
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.password && errors.password.message}
                      </FormErrorMessage>
                    <InputRightElement h={"full"} style={errors.password ? {top:"-33px"} : null}> 
                      <Button
                        variant={"ghost"}
                        onClick={() =>
                          setShowPassword((showPassword) => !showPassword)
                        }
                      >
                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl id="confirm_password" isInvalid={errors.confirm_password} isRequired>
                  <FormLabel htmlFor="confirm_password">{t("confirm_password")}</FormLabel>
                  <Input
                        id="confirm_password"
                        placeholder={t("confirm_password")}
                        type={!showPassword?"password":"text"}
                        style={{display: "block"}}
                        autoComplete="off"
                        {...register('confirm_password', {
                          required: 'This is required',
                          validate: {
                            match: (password) =>
                              password === getValues().password ||
                              "Confirm password should be same as password",
                          }, 
                        })}
                      />
                      <FormErrorMessage>
                        {errors.confirm_password && errors.confirm_password.message}
                      </FormErrorMessage>
                </FormControl>
                </> ) : null }
                
                {activeStep === 0 ? (<Button width={'75px'} alignSelf={'end'} type="button" size="sm" bg={"black"} color={"white"} _hover={{ bg: "black", }}
                    onClick={()=> setActiveStep(1)}
                  > {t("next")} </Button> ) : (<Button width={'75px'} type="button" size="sm" bg={"black"} color={"white"} _hover={{ bg: "black", }}
                    onClick={()=> setActiveStep(0)}
                  > {t("back")}</Button> )}

                <Stack spacing={10} pt={2}>
                  <Button
                    type="submit"
                    loadingText="Submitting"
                    size="lg"
                    bg={"black"}
                    color={"white"}
                    _hover={{
                      bg: "black",
                    }}
                  >
                    {t("signup")}
                  </Button>
                </Stack>
                <Stack pt={6}>
                  <Text align={"center"}>
                    {t("already_have_an_account")}?
                    <Link to="/login">
                      <Text as="u">{t("login")}</Text>
                    </Link>
                  </Text>
                </Stack>
              </Stack>
            </form>
          </Box>

          <ToastContainer theme="colored" />
        </Stack>
      </Flex>
    </div>
  );
}
