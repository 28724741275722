import axios from "../token.interceptor";
import { API_URL } from "../config";
import CommonUtil from "../util/commonUtil";
export default class OrderService {
  constructor() {
    this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";
    this.url = `${API_URL}/${this.lang}/api/v1/orders`;
    this.token = CommonUtil.getAuthToken();

  }

  async createPaymentMethod(FormData) {
    const { data } = await axios.post(`${this.url}/payment/method/`, FormData, {
      headers: this.token,
    });
    return data;
  }

  async getAllPaymentMethods() {
    const { data } = await axios.get(`${this.url}/payment/method/`, {
      headers: this.token,
    });
    return data;
  }

  async createPaymentIntent(FormData) {
    const { data } = await axios.post(`${this.url}/make/`,FormData,{headers: this.token});
    return data;
  }

  async getAllOrders() {
    const { data } = await axios.get(`${this.url}/all/`,{headers: this.token});
    return data;
  }

  async getOrderDetails(id) {
    const { data } = await axios.get(`${this.url}/${id}/`, {
      headers: this.token,
    });
    return data;
  }
}
